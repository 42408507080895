import { ApolloError, gql, useMutation } from '@apollo/client';
import { DeleteResult, LogEntry, QueryLogEntriesArgs } from 'src/generated/dotnet.graphql';
import { logger } from 'src/helpers/logger';
import { GET_INVENTORY } from './useGetInventory';
import { GridType } from 'src/consts';

export const DELETE_INVENTORY = gql`
  mutation DeleteInventory($inventoryId: String!) {
    deleteInventory(inventoryId: $inventoryId) {
      deleteResult {
        deletedIds
        message
        success
      }
    }
  }
`;

export interface InventoryDeleteMutationResponse {
  responseData?: DeleteResult;
  responseMessage: string;
}

interface DeleteInventoryResult {
  removeInventory: (inventoryId: string, variables: QueryLogEntriesArgs, gridType: GridType) => Promise<InventoryDeleteMutationResponse>;
  removeInventoryLoading: boolean;
}

export const useDeleteInventory = (): DeleteInventoryResult => {
  const [deleteInventory, { loading }] = useMutation(DELETE_INVENTORY, {
    onError: (error: ApolloError) => {
      logger('DeleteInventory').error('Error deleting inventory -->', error.message);
    }
  });

  const removeInventory = async (inventoryId: string, queryVariables: QueryLogEntriesArgs, gridType: GridType): Promise<InventoryDeleteMutationResponse> => {
    const refetchQueries = [];

    switch (gridType) {
      case GridType.DEFAULT:
        refetchQueries.push(
          {
            query: GET_INVENTORY,
            variables: queryVariables,
          }
        )
        break;
      default:
        break;
    }

    const response = await deleteInventory({ 
      variables: { inventoryId },
      refetchQueries: refetchQueries,
    });
    const responseData = response.data?.deleteInventory?.deleteResult;
    if (responseData?.success) {
      logger('DeleteInventory').info('Inventory deleted successfully', response.data);
      return {
        responseData,
        responseMessage: `Inventory removed successfully!`
      };
    } else {
      return {
        responseMessage: `Failed to remove inventory!`,
      };
    }
  };

  return { 
    removeInventory, 
    removeInventoryLoading: loading, 
  };
};
