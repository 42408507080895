import { gql, useQuery } from '@apollo/client';
import { GroupCountOfLocation, LogEntryFilterInput } from 'src/generated/dotnet.graphql';
import { logger } from 'src/helpers/logger';

export const GET_LOG_ENTRY_COUNT_BY_LOCATION = gql`
  query GetLogEntryCountsByLocation($filterInput: LogEntryFilterInput) {
    logEntryCountsByLocation(filterInput: $filterInput) {
      count
      item {
        id
        depts
        location
        noView
        tags
        parentId
      }
    }
  }
`;

export interface LocationsResult {
  recordCountsByLocation: GroupCountOfLocation[];
  refetchRecordCountsByLocation: any;
  loading: boolean;
}

export const useGetLogEntryCountsByLocation = (filterInput: LogEntryFilterInput): LocationsResult => {
  const { data, loading, error, refetch } = useQuery(GET_LOG_ENTRY_COUNT_BY_LOCATION, {
    variables: { filterInput },
    notifyOnNetworkStatusChange: true,
  });

  if (error) {
    logger('GetLogEntryCountsByLocation').error('Error fetching locations -->', error.message);
  }

  return {
    recordCountsByLocation: data?.logEntryCountsByLocation || [],
    refetchRecordCountsByLocation: refetch,
    loading,
  };
};
