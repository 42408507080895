import { ApolloError, gql, useMutation } from '@apollo/client';
import { DocumentRevision, DocumentRevisionInput } from 'src/generated/dotnet.graphql';
import { logger } from 'src/helpers/logger';
import { GET_DOCUMENT_REVISIONS } from './useGetDocumentRevisions';

export const UPSERT_DOCUMENT_REVISION = gql`
  mutation UpsertDocumentRevision($input: DocumentRevisionInput!) {
    upsertDocumentRevision(input: $input) {
      documentRevision {
        altPath
        approvedByPe
        approvedByPm
        binderIndex
        binderLocation
        dateEntered
        deletedAt
        deletedBy
        fileName
        fileType
        id
        isRecoverable
        locationId
        notes
        page
        revision
        revisionDate
        status
      }
    }
  }
`;

export interface DocumentRevisionMutationResponse {
  responseData?: DocumentRevision;
  responseMessage: string;
}

interface UpsertDocumentRevisionResult {
  upsertDocumentRevision: (input: DocumentRevisionInput, isCreate: boolean, documentationId: string) => Promise<DocumentRevisionMutationResponse>;
  upsertDocumentRevisionLoading: boolean;
  upsertDocumentRevisionError?: ApolloError;
}

export const useUpsertDocumentRevision = (): UpsertDocumentRevisionResult => {
  const [AddOrUpdateDocumentRevision, { loading, error }] = useMutation(UPSERT_DOCUMENT_REVISION, {
    onError: (error: ApolloError) => {
      logger('UpsertDocumentRevision').error("Error upserting document revision -->", error.message);
    }
  });

  const upsertDocumentRevision = async (input: DocumentRevisionInput, isCreate: boolean, documentationId: string): Promise<DocumentRevisionMutationResponse> => {
    const response = await AddOrUpdateDocumentRevision({ 
      variables: { input },
      refetchQueries: [{
        query: GET_DOCUMENT_REVISIONS,
        variables: { documentationId }
      }],
    });

    const responseData = response.data?.upsertDocumentRevision?.documentRevision;

    if (responseData) {
      logger('UpsertDocumentRevision').info(`Document revision ${isCreate ? 'added' : 'updated'} successfully`, response.data);
      return {
        responseData,
        responseMessage: `Document revision ${isCreate ? 'added' : 'updated'} successfully!`
      };
    } else {
      return {
        responseMessage: `Failed to ${isCreate ? 'add' : 'update'} document revision!`,
      };
    }
  };

  return { 
    upsertDocumentRevision, 
    upsertDocumentRevisionLoading: loading, 
    upsertDocumentRevisionError: error,
  };
};
