import { ApolloClient, createHttpLink, InMemoryCache } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { concatPagination, offsetLimitPagination } from '@apollo/client/utilities';
import { NODE_URL, DOTNET_URL } from 'src/consts';
import { LogEntriesCollectionSegment } from 'src/generated/dotnet.graphql';
import { getPlainTextFromHtml } from 'src/utils';

const nodeHttpLink = createHttpLink({
  uri: `${NODE_URL}/graphql`,
});

const dotnetHttpLink = createHttpLink({
  uri: `${DOTNET_URL}/graphql`,
});

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = localStorage.getItem('token');
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : '',
    },
  };
});

interface NodesData {
  nodes?: any[]; // Use a more specific type instead of `any` if possible
}

interface DataWithNodes extends NodesData {
  [key: string]: any; // Allow other properties
}

// Define a common merge function with types
const mergeNodes = (existing: DataWithNodes = {}, incoming: DataWithNodes): DataWithNodes => {
  return {
    ...incoming,
    nodes: (existing.nodes || []).concat(incoming.nodes),
  };
}

// Define a common read function with types
const readExisting = (existing: DataWithNodes): DataWithNodes => {
  return existing;
}

const cache = new InMemoryCache({
  typePolicies: {
    LogEntry: {keyFields: ["id"]},
    Documentation: {keyFields: ["id"]},
    Inventory: {keyFields: ["id"]},
    Category: {keyFields: ["id"]},
    Location: {keyFields: ["id"]},
    Equipment: {keyFields: ["id"]},
    Comment: {keyFields: ["id"]},
    SparesUsed: {keyFields: ["id"]},
    InventoryLocation: {keyFields: ["id"]},
    Query: {
      fields: {
        logEntries: {
          keyArgs: false,
          merge: mergeNodes,
          read: readExisting,
        },
        documentation: {
          keyArgs: false,
          merge: mergeNodes,
          read: readExisting,
        },
        inventory: {
          keyArgs: false,
          merge: mergeNodes,
          read: readExisting,
        },
        commentsForRecord: {
          keyArgs: false,
          merge: mergeNodes,
          read: readExisting,
        },
        inventoryAtLocation: {
          keyArgs: false,
          merge: mergeNodes,
          read: readExisting,
        },
      },
    },
  },
});

// // TODO: use this config when stop using node server.
// const apolloClient = new ApolloClient({
//   link: authLink.concat(dotnetHttpLink),
//   cache,
// });

// TODO: remove this config when stop using node server.
const apolloClient = new ApolloClient({
  link: authLink.split(
    operation => operation.getContext().clientName === "node-server",
    nodeHttpLink, // apollo will use this endpoint if clientName is "node-server"
    dotnetHttpLink // otherwise will use this endpoint
  ),
  cache,
  // cache: new InMemoryCache()
});

export default apolloClient;