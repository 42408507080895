import { ApolloError, gql, useMutation } from '@apollo/client';
import { Inventory, InventoryInput, QueryDocumentationArgs } from 'src/generated/dotnet.graphql';
import { logger } from 'src/helpers/logger';
import { GET_INVENTORY } from './useGetInventory';

export const UPSERT_INVENTORY = gql`
  mutation UpsertInventory($input: InventoryInput!) {
    upsertInventory(input: $input) {
      inventory {
        id
        productName
        categoryId
        manufacturer
        modelNumber
        partNumber
        department
        sms
        consumable
        bonded
        productDescription
        reOrderLevel
        qty
        orderQty
        factor
        reOrderAmt
        inventoryType
      }
    }
  }
`;

export interface InventoryMutationResponse {
  responseData?: Inventory;
  responseMessage: string;
}

interface UpsertInventoryResult {
  upsertInventory: (input: InventoryInput, isCreate: boolean, queryVariables: QueryDocumentationArgs) => Promise<InventoryMutationResponse>;
  upsertInventoryLoading: boolean;
  upsertInventoryError?: ApolloError;
}

export const useUpsertInventory = (): UpsertInventoryResult => {
  const [addOrUpdateInventory, { loading, error }] = useMutation(UPSERT_INVENTORY, {
    onError: (error: ApolloError) => {
      logger('UpsertInventory').error("Error upserting inventory -->", error.message);
    }
  });

  const upsertInventory = async (input: InventoryInput, isCreate: boolean, refetchVariables: QueryDocumentationArgs): Promise<InventoryMutationResponse> => {
    const response = await addOrUpdateInventory({ 
      variables: { input },
      refetchQueries: [{
        query: GET_INVENTORY,
        variables: refetchVariables
      }],
    });

    const responseData = response.data?.upsertInventory?.inventory;
    if (responseData) {
      logger('UpsertInventory').info(`Inventory ${isCreate ? 'added' : 'updated'} successfully`, response.data);
      return {
        responseData,
        responseMessage: `Inventory ${isCreate ? 'added' : 'updated'} successfully!`
      };
    } else {
      return {
        responseMessage: `Failed to ${isCreate ? 'add' : 'update'} inventory!`,
      };
    }
  };

  return { 
    upsertInventory, 
    upsertInventoryLoading: loading, 
    upsertInventoryError: error,
  };
};
