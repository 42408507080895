import { ApolloError, DocumentNode, gql, useMutation } from '@apollo/client';
import { Equipment, EquipmentForInventoryInput } from 'src/generated/dotnet.graphql';
import { logger } from 'src/helpers/logger';
import { UpdateInventorySpareForEquipmentCache, UpdateLogEntryCommentsCountCache } from '../fragments.graphql';
import { GET__EQUIPMENT_FOR_INVENTORY } from './useGetEquipmentForInventory';

export const ADD_EQUIPMENT_TO_INVENTORY = gql`
  mutation AddEquipmentForInventory($input: EquipmentForInventoryInput!) {
    addEquipmentForInventory(input: $input) {
      equipment {
        id
        uniqueName
      }
    }
  }
`;

export interface EquipmentForInventoryMutationResponse {
  responseData?: Equipment[];
  responseMessage: string;
}

interface AddEquipmentForInventoryResult {
  addEquipmentForInventory: (equipmentIds: string[], inventoryId: string) => Promise<EquipmentForInventoryMutationResponse>;
  addEquipmentForInventoryLoading: boolean;
}

export const useAddEquipmentForInventory = (): AddEquipmentForInventoryResult => {
  const [createEquipmentForInventory, { loading, error }] = useMutation(ADD_EQUIPMENT_TO_INVENTORY, {
    onError: (error: ApolloError) => {
      logger('AddEquipmentForInventory').error(`Error adding equipment for inventory -->`, error.message);
    },  
  });

  const addEquipmentForInventory = async (equipmentIds: string[], inventoryId: string): Promise<EquipmentForInventoryMutationResponse> => {
    const response = await createEquipmentForInventory({ 
      variables: { 
        input: {
          equipmentIds, 
          inventoryId 
        }
      },
      refetchQueries: [{
        query: GET__EQUIPMENT_FOR_INVENTORY,
        variables: { inventoryId }
      }],
    });
    const responseData = response.data?.addEquipmentForInventory?.equipment;
    if (responseData) {
      logger('AddEquipmentForInventory').info('Equipment added successfully', response.data);
      return {
        responseData,
        responseMessage: `Equipment added successfully!`,
      };
    } else {
      return {
        responseMessage: `Failed to add equipment!`,
      };
    }
  };

  return { 
    addEquipmentForInventory, 
    addEquipmentForInventoryLoading: loading, 
  };
};