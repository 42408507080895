import { gql, useQuery } from '@apollo/client';
import { InventoryFilterInput } from 'src/generated/dotnet.graphql';
import { logger } from 'src/helpers/logger';
import { LocationsResult } from '../logEntries/useGetLogEntryCountsByLocation';

export const GET_INVENTORY_COUNTS_BY_LOCATION = gql`
  query GetInventoryCountsByLocation($filterInput: InventoryFilterInput) {
    inventoryCountsByLocation(filterInput: $filterInput) {
      count
      item {
        id
        depts
        location
        noView
        parentId
        tags
      }
    }
  }
`;

export const useGetInventoryCountsByLocation = (filterInput: InventoryFilterInput): LocationsResult => {
  const { data, loading, error, refetch } = useQuery(GET_INVENTORY_COUNTS_BY_LOCATION, {
    variables: { filterInput },
    notifyOnNetworkStatusChange: true,
  });

  if (error) {
    logger('GetInventoryCountsByLocation').error('Error fetching locations -->', error.message);
  }

  return {
    recordCountsByLocation: data?.inventoryCountsByLocation || [],
    refetchRecordCountsByLocation: refetch,
    loading,
  };
};
