import { gql, useQuery  } from "@apollo/client";
import { logger } from "src/helpers/logger";

export const GET_DEFAULT_LABEL_SIZE = gql`
  query GetDefaultLabelSize {
    appSettings {
      defaultLabelSize
    }
  }
`

interface DefaultLabelSizeResult {
  defaultLabelSize: string;
  defaultLabelSizeLoading: boolean;
}

export const useGetDefaultLabelSize = (): DefaultLabelSizeResult => {
  const { data, loading, error } = useQuery(GET_DEFAULT_LABEL_SIZE, {
    notifyOnNetworkStatusChange: true,
  });

  if (error) {
    logger('GetDefaultLabelSize').error('Error fetching default label size -->', error.message);
  }
  
  return {
    defaultLabelSize: data?.appSettings?.defaultLabelSize,
    defaultLabelSizeLoading: loading
  };
};

  