export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** The `Byte` scalar type represents non-fractional whole numeric values. Byte can represent values between 0 and 255. */
  Byte: any;
  /** The `DateTime` scalar represents an ISO-8601 compliant date time type. */
  DateTime: any;
  /** The `Decimal` scalar type represents a decimal floating-point number. */
  Decimal: any;
};



export type AddCommentInput = {
  content: Scalars['String'];
  recordId: Scalars['String'];
  recordType: RecordType;
  parentId?: Maybe<Scalars['String']>;
};

export type AddInventoryToPurchaseOrderInput = {
  inventoryId: Scalars['String'];
  purchaseOrderId: Scalars['String'];
  amount: Scalars['Decimal'];
  unit?: Maybe<Scalars['String']>;
  unitPrice?: Maybe<Scalars['Decimal']>;
  currency?: Maybe<Scalars['String']>;
};

export type AppSettings = {
  __typename?: 'AppSettings';
  defaultLabelSize?: Maybe<Scalars['String']>;
};

/** Defines when a policy shall be executed. */
export enum ApplyPolicy {
  /** Before the resolver was executed. */
  BeforeResolver = 'BEFORE_RESOLVER',
  /** After the resolver was executed. */
  AfterResolver = 'AFTER_RESOLVER',
  /** The policy is applied in the validation step before the execution. */
  Validation = 'VALIDATION'
}

export type Attachment = {
  __typename?: 'Attachment';
  documentationId?: Maybe<Scalars['String']>;
  docTitle: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  showInCentral?: Maybe<Scalars['Boolean']>;
  categoryId?: Maybe<Scalars['String']>;
  libType?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  revision?: Maybe<Scalars['String']>;
  revisionDate?: Maybe<Scalars['DateTime']>;
  altPath?: Maybe<Scalars['Int']>;
  fileName?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  page?: Maybe<Scalars['String']>;
  fileExtension?: Maybe<Scalars['String']>;
  attachmentType: AttachmentType;
  id?: Maybe<Scalars['String']>;
  recordId?: Maybe<Scalars['String']>;
  recordName?: Maybe<Scalars['String']>;
  recordType?: Maybe<Scalars['String']>;
};

export type AttachmentCrossReferenceInput = {
  recordId: Scalars['String'];
  documentationId: Scalars['String'];
};

export type AttachmentInput = {
  id?: Maybe<Scalars['String']>;
  documentationId?: Maybe<Scalars['String']>;
  docTitle?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  showInCentral?: Maybe<Scalars['Boolean']>;
  categoryId?: Maybe<Scalars['String']>;
  libType?: Maybe<Scalars['String']>;
  revision?: Maybe<Scalars['String']>;
  revisionDate?: Maybe<Scalars['DateTime']>;
  altPath?: Maybe<Scalars['Int']>;
  fileName?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  page?: Maybe<Scalars['String']>;
  recordId: Scalars['String'];
  recordType: RecordType;
};

export type AttachmentPayload = {
  __typename?: 'AttachmentPayload';
  attachment?: Maybe<Attachment>;
  errors?: Maybe<Array<UserError>>;
};

export type AttachmentSortInput = {
  documentationId?: Maybe<SortEnumType>;
  docTitle?: Maybe<SortEnumType>;
  description?: Maybe<SortEnumType>;
  showInCentral?: Maybe<SortEnumType>;
  categoryId?: Maybe<SortEnumType>;
  libType?: Maybe<SortEnumType>;
  deletedAt?: Maybe<SortEnumType>;
  revision?: Maybe<SortEnumType>;
  revisionDate?: Maybe<SortEnumType>;
  altPath?: Maybe<SortEnumType>;
  fileName?: Maybe<SortEnumType>;
  notes?: Maybe<SortEnumType>;
  page?: Maybe<SortEnumType>;
  fileExtension?: Maybe<SortEnumType>;
  attachmentType?: Maybe<SortEnumType>;
  id?: Maybe<SortEnumType>;
  recordId?: Maybe<SortEnumType>;
  recordName?: Maybe<SortEnumType>;
  recordType?: Maybe<SortEnumType>;
};

export enum AttachmentType {
  Document = 'DOCUMENT',
  Photo = 'PHOTO',
  Unknown = 'UNKNOWN'
}

export type Barcode = {
  __typename?: 'Barcode';
  barcodeNumber: Scalars['String'];
  inventoryId: Scalars['String'];
  isDefault?: Maybe<Scalars['Boolean']>;
};

export type BarcodeInput = {
  barcodeNumber: Scalars['String'];
  inventoryId: Scalars['String'];
  isDefault?: Maybe<Scalars['Boolean']>;
  allowDuplicates?: Maybe<Scalars['Boolean']>;
};

export type BarcodePayload = {
  __typename?: 'BarcodePayload';
  barcode?: Maybe<Barcode>;
  errors?: Maybe<Array<UserError>>;
};

export type BulkModifyDocumentationInput = {
  ids: Array<Scalars['String']>;
  docTitle?: Maybe<Scalars['String']>;
  received?: Maybe<Scalars['Boolean']>;
  description?: Maybe<Scalars['String']>;
  docPage?: Maybe<Scalars['String']>;
  docFigure?: Maybe<Scalars['String']>;
  addedBy?: Maybe<Scalars['String']>;
  toc?: Maybe<Scalars['String']>;
  number?: Maybe<Scalars['String']>;
  specRef?: Maybe<Scalars['String']>;
  showInCentral?: Maybe<Scalars['Boolean']>;
  isReport?: Maybe<Scalars['Boolean']>;
  password?: Maybe<Scalars['String']>;
  dateEntered?: Maybe<Scalars['DateTime']>;
  libType?: Maybe<Scalars['String']>;
  completeReqd?: Maybe<Scalars['Boolean']>;
  requiresVerification?: Maybe<Scalars['Boolean']>;
  fileType?: Maybe<Scalars['String']>;
  categoryId?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  statusInternal?: Maybe<Scalars['String']>;
};


export type Category = {
  __typename?: 'Category';
  id: Scalars['String'];
  category: Scalars['String'];
  parentId?: Maybe<Scalars['String']>;
  tags?: Maybe<Scalars['String']>;
  depts?: Maybe<Scalars['String']>;
  noView?: Maybe<Scalars['Boolean']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  updatedAt: Scalars['DateTime'];
  deletedBy?: Maybe<Scalars['String']>;
  isRecoverable?: Maybe<Scalars['Boolean']>;
};

export type CategoryFilterInput = {
  tags: Array<Scalars['String']>;
};

export type CategorySortInput = {
  id?: Maybe<SortEnumType>;
  category?: Maybe<SortEnumType>;
  parentId?: Maybe<SortEnumType>;
  tags?: Maybe<SortEnumType>;
  depts?: Maybe<SortEnumType>;
  noView?: Maybe<SortEnumType>;
  deletedAt?: Maybe<SortEnumType>;
  updatedAt?: Maybe<SortEnumType>;
  deletedBy?: Maybe<SortEnumType>;
  isRecoverable?: Maybe<SortEnumType>;
};

/** Information about the offset pagination. */
export type CollectionSegmentInfo = {
  __typename?: 'CollectionSegmentInfo';
  /** Indicates whether more items exist following the set defined by the clients arguments. */
  hasNextPage: Scalars['Boolean'];
  /** Indicates whether more items exist prior the set defined by the clients arguments. */
  hasPreviousPage: Scalars['Boolean'];
};

export type Comment = {
  __typename?: 'Comment';
  id: Scalars['String'];
  content?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  authorId: Scalars['String'];
  parentId?: Maybe<Scalars['String']>;
  parent?: Maybe<Comment>;
  recordId?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  reportId?: Maybe<Scalars['String']>;
  recordType?: Maybe<Scalars['String']>;
  deletedBy?: Maybe<Scalars['String']>;
  isRecoverable?: Maybe<Scalars['Boolean']>;
  author?: Maybe<CrewProfile>;
  replies: Array<Comment>;
};

export type CommentPayload = {
  __typename?: 'CommentPayload';
  comment?: Maybe<Comment>;
  errors?: Maybe<Array<UserError>>;
};

export type CommentSortInput = {
  id?: Maybe<SortEnumType>;
  content?: Maybe<SortEnumType>;
  createdAt?: Maybe<SortEnumType>;
  updatedAt?: Maybe<SortEnumType>;
  authorId?: Maybe<SortEnumType>;
  parentId?: Maybe<SortEnumType>;
  parent?: Maybe<CommentSortInput>;
  recordId?: Maybe<SortEnumType>;
  deletedAt?: Maybe<SortEnumType>;
  reportId?: Maybe<SortEnumType>;
  recordType?: Maybe<SortEnumType>;
  deletedBy?: Maybe<SortEnumType>;
  isRecoverable?: Maybe<SortEnumType>;
  author?: Maybe<CrewProfileSortInput>;
};

export type Company = {
  __typename?: 'Company';
  name?: Maybe<Scalars['String']>;
  module?: Maybe<Scalars['String']>;
  department?: Maybe<Scalars['String']>;
};

export type CompanyFilterInput = {
  and?: Maybe<Array<CompanyFilterInput>>;
  or?: Maybe<Array<CompanyFilterInput>>;
  name?: Maybe<StringOperationFilterInput>;
  module?: Maybe<StringOperationFilterInput>;
  department?: Maybe<StringOperationFilterInput>;
};

export type CompanyName = {
  __typename?: 'CompanyName';
  name?: Maybe<Scalars['String']>;
};

export type CompanySortInput = {
  name?: Maybe<SortEnumType>;
  module?: Maybe<SortEnumType>;
  department?: Maybe<SortEnumType>;
};

export type Crew = {
  __typename?: 'Crew';
  crewId: Scalars['String'];
  first?: Maybe<Scalars['String']>;
  last?: Maybe<Scalars['String']>;
  crewUsername?: Maybe<Scalars['String']>;
};

export type CrewProfile = {
  __typename?: 'CrewProfile';
  crewId: Scalars['String'];
  current?: Maybe<Scalars['Boolean']>;
  crewPassword?: Maybe<Scalars['String']>;
  noView?: Maybe<Scalars['Boolean']>;
  first?: Maybe<Scalars['String']>;
  middle?: Maybe<Scalars['String']>;
  last?: Maybe<Scalars['String']>;
  dob?: Maybe<Scalars['DateTime']>;
  position?: Maybe<Scalars['String']>;
  department?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  passport?: Maybe<Scalars['String']>;
  nationality?: Maybe<Scalars['String']>;
  issue?: Maybe<Scalars['String']>;
  dateIssue?: Maybe<Scalars['DateTime']>;
  dateExpire?: Maybe<Scalars['DateTime']>;
  stationBill?: Maybe<Scalars['String']>;
  picture?: Maybe<Scalars['String']>;
  dl?: Maybe<Scalars['String']>;
  bupa?: Maybe<Scalars['String']>;
  religion?: Maybe<Scalars['String']>;
  food?: Maybe<Scalars['String']>;
  personalNotes?: Maybe<Scalars['String']>;
  height?: Maybe<Scalars['Decimal']>;
  weight?: Maybe<Scalars['Decimal']>;
  shoeSize?: Maybe<Scalars['Decimal']>;
  chest?: Maybe<Scalars['Decimal']>;
  neck?: Maybe<Scalars['Decimal']>;
  waist?: Maybe<Scalars['Decimal']>;
  inseam?: Maybe<Scalars['Decimal']>;
  heightMeas?: Maybe<Scalars['String']>;
  weightMeas?: Maybe<Scalars['String']>;
  shoeMeas?: Maybe<Scalars['String']>;
  chestMeas?: Maybe<Scalars['String']>;
  neckMeas?: Maybe<Scalars['String']>;
  waistMeas?: Maybe<Scalars['String']>;
  inseamMeas?: Maybe<Scalars['String']>;
  laundryNum?: Maybe<Scalars['Int']>;
  allergies?: Maybe<Scalars['String']>;
  meds?: Maybe<Scalars['String']>;
  medInstruct?: Maybe<Scalars['String']>;
  diet?: Maybe<Scalars['String']>;
  medNotes?: Maybe<Scalars['String']>;
  nok1?: Maybe<Scalars['String']>;
  phone1?: Maybe<Scalars['String']>;
  fax1?: Maybe<Scalars['String']>;
  nok1mobile?: Maybe<Scalars['String']>;
  nok1email?: Maybe<Scalars['String']>;
  nok2?: Maybe<Scalars['String']>;
  phone2?: Maybe<Scalars['String']>;
  fax2?: Maybe<Scalars['String']>;
  nok2mobile?: Maybe<Scalars['String']>;
  nok2email?: Maybe<Scalars['String']>;
  dateHire?: Maybe<Scalars['DateTime']>;
  salary?: Maybe<Scalars['Decimal']>;
  currentPay?: Maybe<Scalars['Decimal']>;
  dateFire?: Maybe<Scalars['DateTime']>;
  bank?: Maybe<Scalars['String']>;
  acct?: Maybe<Scalars['String']>;
  wire?: Maybe<Scalars['String']>;
  datePayRev?: Maybe<Scalars['DateTime']>;
  vacDays?: Maybe<Scalars['Decimal']>;
  vacMeas?: Maybe<Scalars['String']>;
  dateVac?: Maybe<Scalars['DateTime']>;
  remarks?: Maybe<Scalars['String']>;
  nameupper?: Maybe<Scalars['String']>;
  isGuest?: Maybe<Scalars['Boolean']>;
  marital?: Maybe<Scalars['String']>;
  placeOfBirth?: Maybe<Scalars['String']>;
  bankPhone?: Maybe<Scalars['String']>;
  bankFax?: Maybe<Scalars['String']>;
  bankEmail?: Maybe<Scalars['String']>;
  bankContact?: Maybe<Scalars['String']>;
  bankRouting?: Maybe<Scalars['String']>;
  bankSwift?: Maybe<Scalars['String']>;
  bankIban?: Maybe<Scalars['String']>;
  bankCurr?: Maybe<Scalars['String']>;
  departmentHead?: Maybe<Scalars['String']>;
  dateEntered?: Maybe<Scalars['DateTime']>;
  isSo?: Maybe<Scalars['Boolean']>;
  isSso?: Maybe<Scalars['Boolean']>;
  isWatchkeeper?: Maybe<Scalars['Boolean']>;
  medConsent?: Maybe<Scalars['Boolean']>;
  bloodAbo?: Maybe<Scalars['String']>;
  bloodRh?: Maybe<Scalars['String']>;
  seaDis?: Maybe<Scalars['Boolean']>;
  certsOnBoard?: Maybe<Scalars['Boolean']>;
  crewFamiliar?: Maybe<Scalars['DateTime']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  defaultPassport?: Maybe<Scalars['String']>;
  crewUsername?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  updatedAt: Scalars['DateTime'];
  deletedBy?: Maybe<Scalars['String']>;
  isRecoverable?: Maybe<Scalars['Boolean']>;
  comments: Array<Comment>;
};

export type CrewProfileSortInput = {
  crewId?: Maybe<SortEnumType>;
  current?: Maybe<SortEnumType>;
  crewPassword?: Maybe<SortEnumType>;
  noView?: Maybe<SortEnumType>;
  first?: Maybe<SortEnumType>;
  middle?: Maybe<SortEnumType>;
  last?: Maybe<SortEnumType>;
  dob?: Maybe<SortEnumType>;
  position?: Maybe<SortEnumType>;
  department?: Maybe<SortEnumType>;
  address?: Maybe<SortEnumType>;
  passport?: Maybe<SortEnumType>;
  nationality?: Maybe<SortEnumType>;
  issue?: Maybe<SortEnumType>;
  dateIssue?: Maybe<SortEnumType>;
  dateExpire?: Maybe<SortEnumType>;
  stationBill?: Maybe<SortEnumType>;
  picture?: Maybe<SortEnumType>;
  dl?: Maybe<SortEnumType>;
  bupa?: Maybe<SortEnumType>;
  religion?: Maybe<SortEnumType>;
  food?: Maybe<SortEnumType>;
  personalNotes?: Maybe<SortEnumType>;
  height?: Maybe<SortEnumType>;
  weight?: Maybe<SortEnumType>;
  shoeSize?: Maybe<SortEnumType>;
  chest?: Maybe<SortEnumType>;
  neck?: Maybe<SortEnumType>;
  waist?: Maybe<SortEnumType>;
  inseam?: Maybe<SortEnumType>;
  heightMeas?: Maybe<SortEnumType>;
  weightMeas?: Maybe<SortEnumType>;
  shoeMeas?: Maybe<SortEnumType>;
  chestMeas?: Maybe<SortEnumType>;
  neckMeas?: Maybe<SortEnumType>;
  waistMeas?: Maybe<SortEnumType>;
  inseamMeas?: Maybe<SortEnumType>;
  laundryNum?: Maybe<SortEnumType>;
  allergies?: Maybe<SortEnumType>;
  meds?: Maybe<SortEnumType>;
  medInstruct?: Maybe<SortEnumType>;
  diet?: Maybe<SortEnumType>;
  medNotes?: Maybe<SortEnumType>;
  nok1?: Maybe<SortEnumType>;
  phone1?: Maybe<SortEnumType>;
  fax1?: Maybe<SortEnumType>;
  nok1mobile?: Maybe<SortEnumType>;
  nok1email?: Maybe<SortEnumType>;
  nok2?: Maybe<SortEnumType>;
  phone2?: Maybe<SortEnumType>;
  fax2?: Maybe<SortEnumType>;
  nok2mobile?: Maybe<SortEnumType>;
  nok2email?: Maybe<SortEnumType>;
  dateHire?: Maybe<SortEnumType>;
  salary?: Maybe<SortEnumType>;
  currentPay?: Maybe<SortEnumType>;
  dateFire?: Maybe<SortEnumType>;
  bank?: Maybe<SortEnumType>;
  acct?: Maybe<SortEnumType>;
  wire?: Maybe<SortEnumType>;
  datePayRev?: Maybe<SortEnumType>;
  vacDays?: Maybe<SortEnumType>;
  vacMeas?: Maybe<SortEnumType>;
  dateVac?: Maybe<SortEnumType>;
  remarks?: Maybe<SortEnumType>;
  nameupper?: Maybe<SortEnumType>;
  isGuest?: Maybe<SortEnumType>;
  marital?: Maybe<SortEnumType>;
  placeOfBirth?: Maybe<SortEnumType>;
  bankPhone?: Maybe<SortEnumType>;
  bankFax?: Maybe<SortEnumType>;
  bankEmail?: Maybe<SortEnumType>;
  bankContact?: Maybe<SortEnumType>;
  bankRouting?: Maybe<SortEnumType>;
  bankSwift?: Maybe<SortEnumType>;
  bankIban?: Maybe<SortEnumType>;
  bankCurr?: Maybe<SortEnumType>;
  departmentHead?: Maybe<SortEnumType>;
  dateEntered?: Maybe<SortEnumType>;
  isSo?: Maybe<SortEnumType>;
  isSso?: Maybe<SortEnumType>;
  isWatchkeeper?: Maybe<SortEnumType>;
  medConsent?: Maybe<SortEnumType>;
  bloodAbo?: Maybe<SortEnumType>;
  bloodRh?: Maybe<SortEnumType>;
  seaDis?: Maybe<SortEnumType>;
  certsOnBoard?: Maybe<SortEnumType>;
  crewFamiliar?: Maybe<SortEnumType>;
  isAdmin?: Maybe<SortEnumType>;
  defaultPassport?: Maybe<SortEnumType>;
  crewUsername?: Maybe<SortEnumType>;
  deletedAt?: Maybe<SortEnumType>;
  updatedAt?: Maybe<SortEnumType>;
  deletedBy?: Maybe<SortEnumType>;
  isRecoverable?: Maybe<SortEnumType>;
};

export type CrewSortInput = {
  crewId?: Maybe<SortEnumType>;
  first?: Maybe<SortEnumType>;
  last?: Maybe<SortEnumType>;
  crewUsername?: Maybe<SortEnumType>;
};

export type Currency = {
  __typename?: 'Currency';
  id: Scalars['String'];
  curr: Scalars['String'];
  abbreviation?: Maybe<Scalars['String']>;
  symbol?: Maybe<Scalars['String']>;
  symbolBefore?: Maybe<Scalars['Boolean']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  updatedAt: Scalars['DateTime'];
  deletedBy?: Maybe<Scalars['String']>;
  isRecoverable?: Maybe<Scalars['Boolean']>;
};

export type CurrencySortInput = {
  id?: Maybe<SortEnumType>;
  curr?: Maybe<SortEnumType>;
  abbreviation?: Maybe<SortEnumType>;
  symbol?: Maybe<SortEnumType>;
  symbolBefore?: Maybe<SortEnumType>;
  deletedAt?: Maybe<SortEnumType>;
  updatedAt?: Maybe<SortEnumType>;
  deletedBy?: Maybe<SortEnumType>;
  isRecoverable?: Maybe<SortEnumType>;
};



export type DeleteLogEntryPayload = {
  __typename?: 'DeleteLogEntryPayload';
  logEntry?: Maybe<LogEntry>;
  errors?: Maybe<Array<UserError>>;
};

export type DeleteResult = {
  __typename?: 'DeleteResult';
  success: Scalars['Boolean'];
  message: Scalars['String'];
  deletedIds: Array<Scalars['String']>;
};

export type DeleteResultPayload = {
  __typename?: 'DeleteResultPayload';
  deleteResult?: Maybe<DeleteResult>;
  errors?: Maybe<Array<UserError>>;
};

export type DocumentRevision = {
  __typename?: 'DocumentRevision';
  id: Scalars['String'];
  documentationId: Scalars['String'];
  fileName?: Maybe<Scalars['String']>;
  revision?: Maybe<Scalars['String']>;
  revisionDate?: Maybe<Scalars['DateTime']>;
  binderIndex?: Maybe<Scalars['String']>;
  binderLocation?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  page?: Maybe<Scalars['String']>;
  approvedByPm?: Maybe<Scalars['DateTime']>;
  approvedByPe?: Maybe<Scalars['DateTime']>;
  altPath?: Maybe<Scalars['Int']>;
  locationId?: Maybe<Scalars['String']>;
  dateEntered?: Maybe<Scalars['DateTime']>;
  fileType?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  updatedAt: Scalars['DateTime'];
  deletedBy?: Maybe<Scalars['String']>;
  isRecoverable?: Maybe<Scalars['Boolean']>;
};

export type DocumentRevisionInput = {
  id?: Maybe<Scalars['String']>;
  documentationId?: Maybe<Scalars['String']>;
  fileName?: Maybe<Scalars['String']>;
  revision?: Maybe<Scalars['String']>;
  revisionDate?: Maybe<Scalars['DateTime']>;
  binderIndex?: Maybe<Scalars['String']>;
  binderLocation?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  page?: Maybe<Scalars['String']>;
  approvedByPm?: Maybe<Scalars['DateTime']>;
  approvedByPe?: Maybe<Scalars['DateTime']>;
  altPath?: Maybe<Scalars['Int']>;
  locationId?: Maybe<Scalars['String']>;
  dateEntered?: Maybe<Scalars['DateTime']>;
  fileType?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
};

export type DocumentRevisionPayload = {
  __typename?: 'DocumentRevisionPayload';
  documentRevision?: Maybe<DocumentRevision>;
  errors?: Maybe<Array<UserError>>;
};

export type Documentation = {
  __typename?: 'Documentation';
  id: Scalars['String'];
  docTitle: Scalars['String'];
  received?: Maybe<Scalars['Boolean']>;
  description?: Maybe<Scalars['String']>;
  docPage?: Maybe<Scalars['String']>;
  docFigure?: Maybe<Scalars['String']>;
  addedBy?: Maybe<Scalars['String']>;
  toc?: Maybe<Scalars['String']>;
  number?: Maybe<Scalars['String']>;
  specRef?: Maybe<Scalars['String']>;
  showInCentral?: Maybe<Scalars['Boolean']>;
  isReport?: Maybe<Scalars['Boolean']>;
  password?: Maybe<Scalars['String']>;
  dateEntered?: Maybe<Scalars['DateTime']>;
  libType?: Maybe<Scalars['String']>;
  completeReqd?: Maybe<Scalars['Boolean']>;
  requiresVerification?: Maybe<Scalars['Boolean']>;
  fileType?: Maybe<Scalars['String']>;
  categoryId?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  statusInternal?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  updatedAt: Scalars['DateTime'];
  deletedBy?: Maybe<Scalars['String']>;
  isRecoverable?: Maybe<Scalars['Boolean']>;
  documentRevisions: Array<DocumentRevision>;
  commentsCount?: Maybe<Scalars['Int']>;
};

/** A segment of a collection. */
export type DocumentationCollectionSegment = {
  __typename?: 'DocumentationCollectionSegment';
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
  /** A flattened list of the items. */
  items?: Maybe<Array<Documentation>>;
  totalCount: Scalars['Int'];
};

export type DocumentationFilterInput = {
  showInCentral?: Maybe<Scalars['Boolean']>;
  libType?: Maybe<DocumentationLibType>;
  docTitle?: Maybe<Scalars['String']>;
  requiresVerification?: Maybe<Scalars['Boolean']>;
  isReport?: Maybe<Scalars['Boolean']>;
  searchText?: Maybe<Scalars['String']>;
  categoryId?: Maybe<Scalars['String']>;
};

export type DocumentationInput = {
  id?: Maybe<Scalars['String']>;
  docTitle?: Maybe<Scalars['String']>;
  received?: Maybe<Scalars['Boolean']>;
  description?: Maybe<Scalars['String']>;
  docPage?: Maybe<Scalars['String']>;
  docFigure?: Maybe<Scalars['String']>;
  addedBy?: Maybe<Scalars['String']>;
  toc?: Maybe<Scalars['String']>;
  number?: Maybe<Scalars['String']>;
  specRef?: Maybe<Scalars['String']>;
  showInCentral?: Maybe<Scalars['Boolean']>;
  isReport?: Maybe<Scalars['Boolean']>;
  password?: Maybe<Scalars['String']>;
  dateEntered?: Maybe<Scalars['DateTime']>;
  libType?: Maybe<Scalars['String']>;
  completeReqd?: Maybe<Scalars['Boolean']>;
  requiresVerification?: Maybe<Scalars['Boolean']>;
  fileType?: Maybe<Scalars['String']>;
  categoryId?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  statusInternal?: Maybe<Scalars['String']>;
};

export enum DocumentationLibType {
  Vesselreference = 'VESSELREFERENCE',
  Manuals = 'MANUALS',
  Drawings = 'DRAWINGS',
  Msds = 'MSDS',
  Photos = 'PHOTOS',
  Smsdocs = 'SMSDOCS',
  Ispsdocs = 'ISPSDOCS'
}

export type DocumentationListPayload = {
  __typename?: 'DocumentationListPayload';
  documentationList?: Maybe<Array<Documentation>>;
  errors?: Maybe<Array<UserError>>;
};

export type DocumentationPayload = {
  __typename?: 'DocumentationPayload';
  documentation?: Maybe<Documentation>;
  errors?: Maybe<Array<UserError>>;
};

export type DocumentationSortInput = {
  id?: Maybe<SortEnumType>;
  docTitle?: Maybe<SortEnumType>;
  received?: Maybe<SortEnumType>;
  description?: Maybe<SortEnumType>;
  docPage?: Maybe<SortEnumType>;
  docFigure?: Maybe<SortEnumType>;
  addedBy?: Maybe<SortEnumType>;
  toc?: Maybe<SortEnumType>;
  number?: Maybe<SortEnumType>;
  specRef?: Maybe<SortEnumType>;
  showInCentral?: Maybe<SortEnumType>;
  isReport?: Maybe<SortEnumType>;
  password?: Maybe<SortEnumType>;
  dateEntered?: Maybe<SortEnumType>;
  libType?: Maybe<SortEnumType>;
  completeReqd?: Maybe<SortEnumType>;
  requiresVerification?: Maybe<SortEnumType>;
  fileType?: Maybe<SortEnumType>;
  categoryId?: Maybe<SortEnumType>;
  status?: Maybe<SortEnumType>;
  statusInternal?: Maybe<SortEnumType>;
  deletedAt?: Maybe<SortEnumType>;
  updatedAt?: Maybe<SortEnumType>;
  deletedBy?: Maybe<SortEnumType>;
  isRecoverable?: Maybe<SortEnumType>;
  commentsCount?: Maybe<SortEnumType>;
};

/** A segment of a collection. */
export type DrillSchedulesCollectionSegment = {
  __typename?: 'DrillSchedulesCollectionSegment';
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
  /** A flattened list of the items. */
  items?: Maybe<Array<Schedule>>;
  totalCount: Scalars['Int'];
};

export type Equipment = {
  __typename?: 'Equipment';
  id: Scalars['String'];
  uniqueName: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  hoursKey?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['Int']>;
  parent?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['Int']>;
  manufacturer?: Maybe<Scalars['String']>;
  modelNumber?: Maybe<Scalars['String']>;
  rating?: Maybe<Scalars['String']>;
  powerRequired?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  voltage?: Maybe<Scalars['String']>;
  weight?: Maybe<Scalars['Decimal']>;
  weightQty?: Maybe<Scalars['String']>;
  department?: Maybe<Scalars['String']>;
  qty?: Maybe<Scalars['String']>;
  searchMod?: Maybe<Scalars['String']>;
  searchMaker?: Maybe<Scalars['String']>;
  serialNum?: Maybe<Scalars['String']>;
  artNumber?: Maybe<Scalars['String']>;
  arrangementNum?: Maybe<Scalars['String']>;
  drawingRef?: Maybe<Scalars['String']>;
  circuitNumber?: Maybe<Scalars['String']>;
  breaker?: Maybe<Scalars['String']>;
  distBoard?: Maybe<Scalars['String']>;
  supplier?: Maybe<Scalars['String']>;
  countHours?: Maybe<Scalars['Boolean']>;
  hoursOffset?: Maybe<Scalars['Int']>;
  dateReset?: Maybe<Scalars['DateTime']>;
  sms?: Maybe<Scalars['Boolean']>;
  locationId?: Maybe<Scalars['String']>;
  expireDate?: Maybe<Scalars['DateTime']>;
  day?: Maybe<Scalars['Byte']>;
  month?: Maybe<Scalars['String']>;
  year?: Maybe<Scalars['String']>;
  restricted?: Maybe<Scalars['Boolean']>;
  hrsEst?: Maybe<Scalars['Int']>;
  dateEntered?: Maybe<Scalars['DateTime']>;
  inService?: Maybe<Scalars['DateTime']>;
  reportingType?: Maybe<Scalars['String']>;
  categoryId?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  updatedAt: Scalars['DateTime'];
  deletedBy?: Maybe<Scalars['String']>;
  isRecoverable?: Maybe<Scalars['Boolean']>;
};

/** A segment of a collection. */
export type EquipmentCollectionSegment = {
  __typename?: 'EquipmentCollectionSegment';
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
  /** A flattened list of the items. */
  items?: Maybe<Array<Equipment>>;
  totalCount: Scalars['Int'];
};

export type EquipmentFilterInput = {
  id?: Maybe<Scalars['String']>;
  categoryId?: Maybe<Scalars['String']>;
  uniqueName?: Maybe<Scalars['String']>;
  excludeIds?: Maybe<Array<Scalars['String']>>;
};

export type EquipmentForInventoryInput = {
  inventoryId: Scalars['String'];
  equipmentIds: Array<Scalars['String']>;
};

export type EquipmentListPayload = {
  __typename?: 'EquipmentListPayload';
  equipment?: Maybe<Array<Equipment>>;
  errors?: Maybe<Array<UserError>>;
};

export type EquipmentSchedule = {
  __typename?: 'EquipmentSchedule';
  equipment?: Maybe<Equipment>;
  id: Scalars['String'];
  subject: Scalars['String'];
  notes?: Maybe<Scalars['String']>;
  hoursKey?: Maybe<Scalars['String']>;
  hourInterval?: Maybe<Scalars['Int']>;
  timeInterval?: Maybe<Scalars['Int']>;
  timeMeasure?: Maybe<Scalars['String']>;
  hourWarn?: Maybe<Scalars['Int']>;
  timeWarn?: Maybe<Scalars['Int']>;
  timeMeasureWarn?: Maybe<Scalars['String']>;
  steps?: Maybe<Scalars['String']>;
  sms?: Maybe<Scalars['Boolean']>;
  recurType?: Maybe<Scalars['Byte']>;
  assignedTo?: Maybe<Scalars['String']>;
  department?: Maybe<Scalars['String']>;
  recurInterval?: Maybe<Scalars['Int']>;
  recurPattern?: Maybe<Scalars['String']>;
  toolsReqd?: Maybe<Scalars['String']>;
  hourLock?: Maybe<Scalars['Boolean']>;
  ttlwork?: Maybe<Scalars['String']>;
  csm?: Maybe<Scalars['Boolean']>;
  schedType?: Maybe<Scalars['String']>;
  userDefined?: Maybe<Scalars['String']>;
  listType?: Maybe<Scalars['String']>;
  duration?: Maybe<Scalars['Int']>;
  dateEntered?: Maybe<Scalars['DateTime']>;
  isCheckList?: Maybe<Scalars['Boolean']>;
  linkId?: Maybe<Scalars['String']>;
  quotedPrice?: Maybe<Scalars['Decimal']>;
  quotedCurrency?: Maybe<Scalars['String']>;
  rtf?: Maybe<Scalars['String']>;
  plainText?: Maybe<Scalars['String']>;
  html?: Maybe<Scalars['String']>;
  categoryId?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  updatedAt: Scalars['DateTime'];
  deletedBy?: Maybe<Scalars['String']>;
  isRecoverable?: Maybe<Scalars['Boolean']>;
  equipmentId?: Maybe<Scalars['String']>;
  hoursTrigger?: Maybe<Scalars['Int']>;
  hoursCompleted?: Maybe<Scalars['Int']>;
  dateTrigger?: Maybe<Scalars['DateTime']>;
  runOnce?: Maybe<Scalars['Boolean']>;
  iterations?: Maybe<Scalars['Int']>;
  deferred?: Maybe<Scalars['Boolean']>;
  index?: Maybe<Scalars['Int']>;
  deferredDate?: Maybe<Scalars['DateTime']>;
  locationId?: Maybe<Scalars['String']>;
};

export type EquipmentScheduleFilterInput = {
  subject?: Maybe<Scalars['String']>;
  equipmentName?: Maybe<Scalars['String']>;
  excludeIds?: Maybe<Array<Scalars['String']>>;
};

export type EquipmentScheduleSortInput = {
  equipment?: Maybe<EquipmentSortInput>;
  id?: Maybe<SortEnumType>;
  subject?: Maybe<SortEnumType>;
  notes?: Maybe<SortEnumType>;
  hoursKey?: Maybe<SortEnumType>;
  hourInterval?: Maybe<SortEnumType>;
  timeInterval?: Maybe<SortEnumType>;
  timeMeasure?: Maybe<SortEnumType>;
  hourWarn?: Maybe<SortEnumType>;
  timeWarn?: Maybe<SortEnumType>;
  timeMeasureWarn?: Maybe<SortEnumType>;
  steps?: Maybe<SortEnumType>;
  sms?: Maybe<SortEnumType>;
  recurType?: Maybe<SortEnumType>;
  assignedTo?: Maybe<SortEnumType>;
  department?: Maybe<SortEnumType>;
  recurInterval?: Maybe<SortEnumType>;
  recurPattern?: Maybe<SortEnumType>;
  toolsReqd?: Maybe<SortEnumType>;
  hourLock?: Maybe<SortEnumType>;
  ttlwork?: Maybe<SortEnumType>;
  csm?: Maybe<SortEnumType>;
  schedType?: Maybe<SortEnumType>;
  userDefined?: Maybe<SortEnumType>;
  listType?: Maybe<SortEnumType>;
  duration?: Maybe<SortEnumType>;
  dateEntered?: Maybe<SortEnumType>;
  isCheckList?: Maybe<SortEnumType>;
  linkId?: Maybe<SortEnumType>;
  quotedPrice?: Maybe<SortEnumType>;
  quotedCurrency?: Maybe<SortEnumType>;
  rtf?: Maybe<SortEnumType>;
  plainText?: Maybe<SortEnumType>;
  html?: Maybe<SortEnumType>;
  categoryId?: Maybe<SortEnumType>;
  deletedAt?: Maybe<SortEnumType>;
  updatedAt?: Maybe<SortEnumType>;
  deletedBy?: Maybe<SortEnumType>;
  isRecoverable?: Maybe<SortEnumType>;
  equipmentId?: Maybe<SortEnumType>;
  hoursTrigger?: Maybe<SortEnumType>;
  hoursCompleted?: Maybe<SortEnumType>;
  dateTrigger?: Maybe<SortEnumType>;
  runOnce?: Maybe<SortEnumType>;
  iterations?: Maybe<SortEnumType>;
  deferred?: Maybe<SortEnumType>;
  index?: Maybe<SortEnumType>;
  deferredDate?: Maybe<SortEnumType>;
  locationId?: Maybe<SortEnumType>;
};

/** A segment of a collection. */
export type EquipmentSchedulesCollectionSegment = {
  __typename?: 'EquipmentSchedulesCollectionSegment';
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
  /** A flattened list of the items. */
  items?: Maybe<Array<EquipmentSchedule>>;
  totalCount: Scalars['Int'];
};

export type EquipmentSortInput = {
  id?: Maybe<SortEnumType>;
  uniqueName?: Maybe<SortEnumType>;
  name?: Maybe<SortEnumType>;
  hoursKey?: Maybe<SortEnumType>;
  hours?: Maybe<SortEnumType>;
  parent?: Maybe<SortEnumType>;
  amount?: Maybe<SortEnumType>;
  manufacturer?: Maybe<SortEnumType>;
  modelNumber?: Maybe<SortEnumType>;
  rating?: Maybe<SortEnumType>;
  powerRequired?: Maybe<SortEnumType>;
  notes?: Maybe<SortEnumType>;
  voltage?: Maybe<SortEnumType>;
  weight?: Maybe<SortEnumType>;
  weightQty?: Maybe<SortEnumType>;
  department?: Maybe<SortEnumType>;
  qty?: Maybe<SortEnumType>;
  searchMod?: Maybe<SortEnumType>;
  searchMaker?: Maybe<SortEnumType>;
  serialNum?: Maybe<SortEnumType>;
  artNumber?: Maybe<SortEnumType>;
  arrangementNum?: Maybe<SortEnumType>;
  drawingRef?: Maybe<SortEnumType>;
  circuitNumber?: Maybe<SortEnumType>;
  breaker?: Maybe<SortEnumType>;
  distBoard?: Maybe<SortEnumType>;
  supplier?: Maybe<SortEnumType>;
  countHours?: Maybe<SortEnumType>;
  hoursOffset?: Maybe<SortEnumType>;
  dateReset?: Maybe<SortEnumType>;
  sms?: Maybe<SortEnumType>;
  locationId?: Maybe<SortEnumType>;
  expireDate?: Maybe<SortEnumType>;
  day?: Maybe<SortEnumType>;
  month?: Maybe<SortEnumType>;
  year?: Maybe<SortEnumType>;
  restricted?: Maybe<SortEnumType>;
  hrsEst?: Maybe<SortEnumType>;
  dateEntered?: Maybe<SortEnumType>;
  inService?: Maybe<SortEnumType>;
  reportingType?: Maybe<SortEnumType>;
  categoryId?: Maybe<SortEnumType>;
  deletedAt?: Maybe<SortEnumType>;
  updatedAt?: Maybe<SortEnumType>;
  deletedBy?: Maybe<SortEnumType>;
  isRecoverable?: Maybe<SortEnumType>;
};

/** A segment of a collection. */
export type EventSchedulesCollectionSegment = {
  __typename?: 'EventSchedulesCollectionSegment';
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
  /** A flattened list of the items. */
  items?: Maybe<Array<Schedule>>;
  totalCount: Scalars['Int'];
};

export type GridSavedView = {
  __typename?: 'GridSavedView';
  id: Scalars['String'];
  crewId?: Maybe<Scalars['String']>;
  layout?: Maybe<Scalars['String']>;
  module?: Maybe<Scalars['String']>;
  viewName?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  updatedAt: Scalars['DateTime'];
  deletedBy?: Maybe<Scalars['String']>;
  isRecoverable?: Maybe<Scalars['Boolean']>;
};

export type GridSavedViewInput = {
  id?: Maybe<Scalars['String']>;
  crewId?: Maybe<Scalars['String']>;
  layout: Scalars['String'];
  module: Scalars['String'];
  viewName: Scalars['String'];
};

export type GridSavedViewPayload = {
  __typename?: 'GridSavedViewPayload';
  view?: Maybe<GridSavedView>;
  errors?: Maybe<Array<UserError>>;
};

export type GroupCountOfCategory = {
  __typename?: 'GroupCountOfCategory';
  item?: Maybe<Category>;
  count: Scalars['Int'];
};

export type GroupCountOfLocation = {
  __typename?: 'GroupCountOfLocation';
  item?: Maybe<Location>;
  count: Scalars['Int'];
};

export type GroupListOfEquipmentAndLogEntry = {
  __typename?: 'GroupListOfEquipmentAndLogEntry';
  item?: Maybe<Equipment>;
  list?: Maybe<Array<Maybe<LogEntry>>>;
  count: Scalars['Int'];
};

/** A segment of a collection. */
export type InspectionsSchedulesCollectionSegment = {
  __typename?: 'InspectionsSchedulesCollectionSegment';
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
  /** A flattened list of the items. */
  items?: Maybe<Array<Schedule>>;
  totalCount: Scalars['Int'];
};

export type Inventory = {
  __typename?: 'Inventory';
  productName: Scalars['String'];
  productDescription?: Maybe<Scalars['String']>;
  unitsOnOrder?: Maybe<Scalars['Int']>;
  reOrderLevel?: Maybe<Scalars['Int']>;
  modelNumber?: Maybe<Scalars['String']>;
  department?: Maybe<Scalars['String']>;
  reOrder?: Maybe<Scalars['Boolean']>;
  manufacturer?: Maybe<Scalars['String']>;
  qty?: Maybe<Scalars['String']>;
  weight?: Maybe<Scalars['Decimal']>;
  powerRequired?: Maybe<Scalars['String']>;
  weightQty?: Maybe<Scalars['String']>;
  bonded?: Maybe<Scalars['Boolean']>;
  noView?: Maybe<Scalars['Boolean']>;
  consumable?: Maybe<Scalars['Boolean']>;
  useQty?: Maybe<Scalars['String']>;
  factor?: Maybe<Scalars['Decimal']>;
  orderQty?: Maybe<Scalars['String']>;
  orderAmt?: Maybe<Scalars['Int']>;
  reOrderAmt?: Maybe<Scalars['Int']>;
  inventoryType?: Maybe<InventoryType>;
  region?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  size?: Maybe<Scalars['String']>;
  color?: Maybe<Scalars['String']>;
  llbc?: Maybe<Scalars['String']>;
  partNumber?: Maybe<Scalars['String']>;
  guestType?: Maybe<Scalars['String']>;
  sms?: Maybe<Scalars['Boolean']>;
  dateEntered?: Maybe<Scalars['DateTime']>;
  rx?: Maybe<Scalars['Boolean']>;
  photo?: Maybe<Scalars['String']>;
  multipleLocations: Array<MultipleLocation>;
  barcodes?: Maybe<Array<Barcode>>;
  expiredAtLocation: Scalars['Boolean'];
  lowStock: Scalars['Boolean'];
  totalAmount: Scalars['Decimal'];
  equipmentCount: Scalars['Int'];
  ordersCount: Scalars['Int'];
  id: Scalars['String'];
  categoryId?: Maybe<Scalars['String']>;
  category?: Maybe<Category>;
  photosCount?: Maybe<Scalars['Int']>;
  documentsCount?: Maybe<Scalars['Int']>;
  attachments: Array<Attachment>;
  commentsCount?: Maybe<Scalars['Int']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  updatedAt: Scalars['DateTime'];
  deletedBy?: Maybe<Scalars['String']>;
  isRecoverable?: Maybe<Scalars['Boolean']>;
};

/** A segment of a collection. */
export type InventoryCollectionSegment = {
  __typename?: 'InventoryCollectionSegment';
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
  /** A flattened list of the items. */
  items?: Maybe<Array<Inventory>>;
  totalCount: Scalars['Int'];
};

export type InventoryFilterInput = {
  searchText?: Maybe<Scalars['String']>;
  department?: Maybe<Scalars['String']>;
  excludeIds?: Maybe<Array<Scalars['String']>>;
  categoryId?: Maybe<Scalars['String']>;
  locationId?: Maybe<Scalars['String']>;
  inventoryType?: Maybe<InventoryType>;
  productName?: Maybe<Scalars['String']>;
  manufacturer?: Maybe<Scalars['String']>;
  partNumber?: Maybe<Scalars['String']>;
  modelNumber?: Maybe<Scalars['String']>;
  isInStock?: Maybe<Scalars['Boolean']>;
  isBelowMinimum?: Maybe<Scalars['Boolean']>;
  hasExpiredAtALocation?: Maybe<Scalars['Boolean']>;
  isConsumable?: Maybe<Scalars['Boolean']>;
  isCritical?: Maybe<Scalars['Boolean']>;
};

export type InventoryInput = {
  id?: Maybe<Scalars['String']>;
  categoryId?: Maybe<Scalars['String']>;
  productName?: Maybe<Scalars['String']>;
  productDescription?: Maybe<Scalars['String']>;
  unitsOnOrder?: Maybe<Scalars['Int']>;
  reOrderLevel?: Maybe<Scalars['Int']>;
  modelNumber?: Maybe<Scalars['String']>;
  department?: Maybe<Scalars['String']>;
  reOrder?: Maybe<Scalars['Boolean']>;
  manufacturer?: Maybe<Scalars['String']>;
  qty?: Maybe<Scalars['String']>;
  weight?: Maybe<Scalars['Decimal']>;
  powerRequired?: Maybe<Scalars['String']>;
  weightQty?: Maybe<Scalars['String']>;
  bonded?: Maybe<Scalars['Boolean']>;
  noView?: Maybe<Scalars['Boolean']>;
  consumable?: Maybe<Scalars['Boolean']>;
  useQty?: Maybe<Scalars['String']>;
  factor?: Maybe<Scalars['Decimal']>;
  orderQty?: Maybe<Scalars['String']>;
  orderAmt?: Maybe<Scalars['Int']>;
  reOrderAmt?: Maybe<Scalars['Int']>;
  inventoryType?: Maybe<InventoryType>;
  region?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  size?: Maybe<Scalars['String']>;
  color?: Maybe<Scalars['String']>;
  llbc?: Maybe<Scalars['String']>;
  partNumber?: Maybe<Scalars['String']>;
  guestType?: Maybe<Scalars['String']>;
  sms?: Maybe<Scalars['Boolean']>;
  dateEntered?: Maybe<Scalars['DateTime']>;
  rx?: Maybe<Scalars['Boolean']>;
};

export type InventoryLocation = {
  __typename?: 'InventoryLocation';
  id: Scalars['String'];
  inventoryId: Scalars['String'];
  inventory: Inventory;
  serialNum?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['Decimal']>;
  locationId?: Maybe<Scalars['String']>;
  locationPath?: Maybe<Scalars['String']>;
  useBy?: Maybe<Scalars['DateTime']>;
  isDefault?: Maybe<Scalars['Boolean']>;
  max?: Maybe<Scalars['Decimal']>;
  dateEntered?: Maybe<Scalars['DateTime']>;
};

export type InventoryLocationInput = {
  id?: Maybe<Scalars['String']>;
  inventoryId?: Maybe<Scalars['String']>;
  locationId?: Maybe<Scalars['String']>;
  serialNum?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['Decimal']>;
  useBy?: Maybe<Scalars['DateTime']>;
  isDefault?: Maybe<Scalars['Boolean']>;
  max?: Maybe<Scalars['Decimal']>;
};

export type InventoryLocationPayload = {
  __typename?: 'InventoryLocationPayload';
  inventoryLocation?: Maybe<InventoryLocation>;
  errors?: Maybe<Array<UserError>>;
};

export type InventoryOrderHistory = {
  __typename?: 'InventoryOrderHistory';
  id: Scalars['String'];
  orderDate: Scalars['DateTime'];
  orderName?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  inventoryId?: Maybe<Scalars['String']>;
  amountOrdered?: Maybe<Scalars['Decimal']>;
  amountReceived?: Maybe<Scalars['Decimal']>;
};

export type InventoryPayload = {
  __typename?: 'InventoryPayload';
  inventory?: Maybe<Inventory>;
  errors?: Maybe<Array<UserError>>;
};

export type InventorySortInput = {
  productName?: Maybe<SortEnumType>;
  productDescription?: Maybe<SortEnumType>;
  unitsOnOrder?: Maybe<SortEnumType>;
  reOrderLevel?: Maybe<SortEnumType>;
  modelNumber?: Maybe<SortEnumType>;
  department?: Maybe<SortEnumType>;
  reOrder?: Maybe<SortEnumType>;
  manufacturer?: Maybe<SortEnumType>;
  qty?: Maybe<SortEnumType>;
  weight?: Maybe<SortEnumType>;
  powerRequired?: Maybe<SortEnumType>;
  weightQty?: Maybe<SortEnumType>;
  bonded?: Maybe<SortEnumType>;
  noView?: Maybe<SortEnumType>;
  consumable?: Maybe<SortEnumType>;
  useQty?: Maybe<SortEnumType>;
  factor?: Maybe<SortEnumType>;
  orderQty?: Maybe<SortEnumType>;
  orderAmt?: Maybe<SortEnumType>;
  reOrderAmt?: Maybe<SortEnumType>;
  inventoryType?: Maybe<SortEnumType>;
  region?: Maybe<SortEnumType>;
  country?: Maybe<SortEnumType>;
  size?: Maybe<SortEnumType>;
  color?: Maybe<SortEnumType>;
  llbc?: Maybe<SortEnumType>;
  partNumber?: Maybe<SortEnumType>;
  guestType?: Maybe<SortEnumType>;
  sms?: Maybe<SortEnumType>;
  dateEntered?: Maybe<SortEnumType>;
  rx?: Maybe<SortEnumType>;
  photo?: Maybe<SortEnumType>;
  expiredAtLocation?: Maybe<SortEnumType>;
  lowStock?: Maybe<SortEnumType>;
  totalAmount?: Maybe<SortEnumType>;
  equipmentCount?: Maybe<SortEnumType>;
  ordersCount?: Maybe<SortEnumType>;
  id?: Maybe<SortEnumType>;
  categoryId?: Maybe<SortEnumType>;
  category?: Maybe<CategorySortInput>;
  photosCount?: Maybe<SortEnumType>;
  documentsCount?: Maybe<SortEnumType>;
  commentsCount?: Maybe<SortEnumType>;
  deletedAt?: Maybe<SortEnumType>;
  updatedAt?: Maybe<SortEnumType>;
  deletedBy?: Maybe<SortEnumType>;
  isRecoverable?: Maybe<SortEnumType>;
};

export enum InventoryType {
  GeneralInventory = 'GENERAL_INVENTORY',
  Beverages = 'BEVERAGES',
  DryAndColdStore = 'DRY_AND_COLD_STORE',
  Uniform = 'UNIFORM',
  Medical = 'MEDICAL'
}

export type List = {
  __typename?: 'List';
  id: Scalars['String'];
  listName?: Maybe<Scalars['String']>;
  member?: Maybe<Scalars['String']>;
  t4reqd: Scalars['Boolean'];
  index?: Maybe<Scalars['Int']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  updatedAt: Scalars['DateTime'];
  deletedBy?: Maybe<Scalars['String']>;
  isRecoverable?: Maybe<Scalars['Boolean']>;
};

export type Location = {
  __typename?: 'Location';
  id: Scalars['String'];
  location: Scalars['String'];
  parentId?: Maybe<Scalars['String']>;
  tags?: Maybe<Scalars['String']>;
  depts?: Maybe<Scalars['String']>;
  noView?: Maybe<Scalars['Boolean']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  updatedAt: Scalars['DateTime'];
  deletedBy?: Maybe<Scalars['String']>;
  isRecoverable?: Maybe<Scalars['Boolean']>;
};

export type LocationSortInput = {
  id?: Maybe<SortEnumType>;
  location?: Maybe<SortEnumType>;
  parentId?: Maybe<SortEnumType>;
  tags?: Maybe<SortEnumType>;
  depts?: Maybe<SortEnumType>;
  noView?: Maybe<SortEnumType>;
  deletedAt?: Maybe<SortEnumType>;
  updatedAt?: Maybe<SortEnumType>;
  deletedBy?: Maybe<SortEnumType>;
  isRecoverable?: Maybe<SortEnumType>;
};

/** A segment of a collection. */
export type LogEntriesCollectionSegment = {
  __typename?: 'LogEntriesCollectionSegment';
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
  /** A flattened list of the items. */
  items?: Maybe<Array<LogEntry>>;
  totalCount: Scalars['Int'];
};

export type LogEntry = {
  __typename?: 'LogEntry';
  description: Scalars['String'];
  logDate: Scalars['DateTime'];
  month: Scalars['String'];
  year: Scalars['String'];
  equipmentName?: Maybe<Scalars['String']>;
  dayLogId?: Maybe<Scalars['String']>;
  scheduleId?: Maybe<Scalars['String']>;
  equipmentId?: Maybe<Scalars['String']>;
  workIssueId?: Maybe<Scalars['String']>;
  workIssue?: Maybe<WorkIssue>;
  dayLogType?: Maybe<Scalars['Byte']>;
  cost?: Maybe<Scalars['Decimal']>;
  curr?: Maybe<Scalars['String']>;
  company?: Maybe<Scalars['String']>;
  time?: Maybe<Scalars['Decimal']>;
  timeQty?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['Int']>;
  performedBy?: Maybe<Scalars['String']>;
  enteredBy?: Maybe<Scalars['String']>;
  tsachk?: Maybe<Scalars['Boolean']>;
  geoLocation?: Maybe<Scalars['String']>;
  department?: Maybe<Scalars['String']>;
  logEntryTime?: Maybe<Scalars['DateTime']>;
  crewId?: Maybe<Scalars['String']>;
  noView?: Maybe<Scalars['Boolean']>;
  restricted?: Maybe<Scalars['Boolean']>;
  billable?: Maybe<Scalars['Boolean']>;
  isWarranty?: Maybe<Scalars['Boolean']>;
  logEntryRtf?: Maybe<Scalars['String']>;
  locationId?: Maybe<Scalars['String']>;
  location?: Maybe<Location>;
  dateEntered?: Maybe<Scalars['DateTime']>;
  relEntry?: Maybe<Scalars['Boolean']>;
  isCheckList?: Maybe<Scalars['Boolean']>;
  workList?: Maybe<Scalars['String']>;
  relatedKey?: Maybe<Scalars['String']>;
  html?: Maybe<Scalars['String']>;
  sms?: Maybe<Scalars['Boolean']>;
  checks?: Maybe<Scalars['String']>;
  equipment?: Maybe<Equipment>;
  sparesUsedCount: Scalars['Int'];
  id: Scalars['String'];
  categoryId?: Maybe<Scalars['String']>;
  category?: Maybe<Category>;
  photosCount?: Maybe<Scalars['Int']>;
  documentsCount?: Maybe<Scalars['Int']>;
  attachments: Array<Attachment>;
  commentsCount?: Maybe<Scalars['Int']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  updatedAt: Scalars['DateTime'];
  deletedBy?: Maybe<Scalars['String']>;
  isRecoverable?: Maybe<Scalars['Boolean']>;
};

export enum LogEntryFilterBy {
  Category = 'CATEGORY',
  Location = 'LOCATION'
}

export type LogEntryFilterInput = {
  logDateStart?: Maybe<Scalars['DateTime']>;
  logDateEnd?: Maybe<Scalars['DateTime']>;
  html?: Maybe<Scalars['String']>;
  department?: Maybe<Scalars['String']>;
  equipment?: Maybe<Scalars['String']>;
  workList?: Maybe<Scalars['String']>;
  performedBy?: Maybe<Scalars['String']>;
  enteredBy?: Maybe<Scalars['String']>;
  searchText?: Maybe<Scalars['String']>;
  sms?: Maybe<Scalars['Boolean']>;
  restricted?: Maybe<Scalars['Boolean']>;
  isWarranty?: Maybe<Scalars['Boolean']>;
  equipmentId?: Maybe<Scalars['String']>;
  scheduleId?: Maybe<Scalars['String']>;
  includeScheduled?: Maybe<Scalars['Boolean']>;
  includeUnscheduled?: Maybe<Scalars['Boolean']>;
};

export type LogEntryInput = {
  id?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  logDate?: Maybe<Scalars['DateTime']>;
  equipmentId?: Maybe<Scalars['String']>;
  categoryId?: Maybe<Scalars['String']>;
  locationId?: Maybe<Scalars['String']>;
  workList?: Maybe<Scalars['String']>;
  department?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['Int']>;
  enteredBy?: Maybe<Scalars['String']>;
  performedBy?: Maybe<Scalars['String']>;
  company?: Maybe<Scalars['String']>;
  time?: Maybe<Scalars['Decimal']>;
  timeQty?: Maybe<Scalars['String']>;
  cost?: Maybe<Scalars['Decimal']>;
  curr?: Maybe<Scalars['String']>;
  isWarranty?: Maybe<Scalars['Boolean']>;
  html?: Maybe<Scalars['String']>;
  crewId?: Maybe<Scalars['String']>;
  restricted?: Maybe<Scalars['Boolean']>;
  sms?: Maybe<Scalars['Boolean']>;
  dayLogType?: Maybe<Scalars['Byte']>;
};

export type LogEntryPayload = {
  __typename?: 'LogEntryPayload';
  logEntry?: Maybe<LogEntry>;
  errors?: Maybe<Array<UserError>>;
};

export type LogEntrySortInput = {
  description?: Maybe<SortEnumType>;
  logDate?: Maybe<SortEnumType>;
  month?: Maybe<SortEnumType>;
  year?: Maybe<SortEnumType>;
  equipmentName?: Maybe<SortEnumType>;
  dayLogId?: Maybe<SortEnumType>;
  scheduleId?: Maybe<SortEnumType>;
  equipmentId?: Maybe<SortEnumType>;
  workIssueId?: Maybe<SortEnumType>;
  workIssue?: Maybe<WorkIssueSortInput>;
  dayLogType?: Maybe<SortEnumType>;
  cost?: Maybe<SortEnumType>;
  curr?: Maybe<SortEnumType>;
  company?: Maybe<SortEnumType>;
  time?: Maybe<SortEnumType>;
  timeQty?: Maybe<SortEnumType>;
  hours?: Maybe<SortEnumType>;
  performedBy?: Maybe<SortEnumType>;
  enteredBy?: Maybe<SortEnumType>;
  tsachk?: Maybe<SortEnumType>;
  geoLocation?: Maybe<SortEnumType>;
  department?: Maybe<SortEnumType>;
  logEntryTime?: Maybe<SortEnumType>;
  crewId?: Maybe<SortEnumType>;
  noView?: Maybe<SortEnumType>;
  restricted?: Maybe<SortEnumType>;
  billable?: Maybe<SortEnumType>;
  isWarranty?: Maybe<SortEnumType>;
  logEntryRtf?: Maybe<SortEnumType>;
  locationId?: Maybe<SortEnumType>;
  location?: Maybe<LocationSortInput>;
  dateEntered?: Maybe<SortEnumType>;
  relEntry?: Maybe<SortEnumType>;
  isCheckList?: Maybe<SortEnumType>;
  workList?: Maybe<SortEnumType>;
  relatedKey?: Maybe<SortEnumType>;
  html?: Maybe<SortEnumType>;
  sms?: Maybe<SortEnumType>;
  checks?: Maybe<SortEnumType>;
  equipment?: Maybe<EquipmentSortInput>;
  sparesUsedCount?: Maybe<SortEnumType>;
  id?: Maybe<SortEnumType>;
  categoryId?: Maybe<SortEnumType>;
  category?: Maybe<CategorySortInput>;
  photosCount?: Maybe<SortEnumType>;
  documentsCount?: Maybe<SortEnumType>;
  commentsCount?: Maybe<SortEnumType>;
  deletedAt?: Maybe<SortEnumType>;
  updatedAt?: Maybe<SortEnumType>;
  deletedBy?: Maybe<SortEnumType>;
  isRecoverable?: Maybe<SortEnumType>;
};

export type ModifyCommentInput = {
  id: Scalars['String'];
  content: Scalars['String'];
};

export type ModifyLogEntriesPayload = {
  __typename?: 'ModifyLogEntriesPayload';
  logEntries?: Maybe<Array<LogEntry>>;
  errors?: Maybe<Array<UserError>>;
};

export type MultipleLocation = {
  __typename?: 'MultipleLocation';
  id: Scalars['String'];
  inventoryId: Scalars['String'];
  inventory: Inventory;
  serialNum?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['Decimal']>;
  useBy?: Maybe<Scalars['DateTime']>;
  default?: Maybe<Scalars['Boolean']>;
  locationId?: Maybe<Scalars['String']>;
  dateEntered?: Maybe<Scalars['DateTime']>;
  maxHldgLoc?: Maybe<Scalars['Decimal']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  updatedAt: Scalars['DateTime'];
  deletedBy?: Maybe<Scalars['String']>;
  isRecoverable?: Maybe<Scalars['Boolean']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  addAttachment: AttachmentPayload;
  modifyAttachment: AttachmentPayload;
  upsertAttachment: AttachmentPayload;
  deleteAttachmentFromRecord: AttachmentPayload;
  addComment: CommentPayload;
  modifyComment: CommentPayload;
  deleteComment: CommentPayload;
  addDocumentation: DocumentationPayload;
  modifyDocumentation: DocumentationPayload;
  bulkModifyDocumentations: DocumentationListPayload;
  upsertDocumentation: DocumentationPayload;
  deleteDocumentation: DocumentationPayload;
  deleteDocumentations: DocumentationListPayload;
  addDocumentRevision: DocumentRevisionPayload;
  modifyDocumentRevision: DocumentRevisionPayload;
  upsertDocumentRevision: DocumentRevisionPayload;
  deleteDocumentRevision: DocumentRevisionPayload;
  addEquipmentForInventory: EquipmentListPayload;
  removeEquipmentFromInventory: EquipmentListPayload;
  addInventory: InventoryPayload;
  modifyInventory?: Maybe<InventoryPayload>;
  upsertInventory?: Maybe<InventoryPayload>;
  deleteInventory?: Maybe<DeleteResultPayload>;
  addInventoryToLocation: InventoryLocationPayload;
  modifyInventoryLocation: InventoryLocationPayload;
  upsertInventoryLocation: InventoryLocationPayload;
  removeInventoryFromLocation: DeleteResultPayload;
  addBarcode: BarcodePayload;
  removeBarcode: DeleteResultPayload;
  addLogEntry: LogEntryPayload;
  upsertLogEntry?: Maybe<LogEntryPayload>;
  modifyLogEntry?: Maybe<LogEntryPayload>;
  deleteLogEntry?: Maybe<DeleteLogEntryPayload>;
  deleteLogEntries?: Maybe<ModifyLogEntriesPayload>;
  addInventoryToPurchaseOrder: Order;
  addSpare: SparePayload;
  modifySpare: SparePayload;
  upsertSpare: SparePayload;
  deleteSpare: SparePayload;
  upsertGridSavedView: GridSavedViewPayload;
  createGridSavedView: GridSavedViewPayload;
  modifyGridSavedView: GridSavedViewPayload;
  deleteGridSavedView: GridSavedViewPayload;
};


export type MutationAddAttachmentArgs = {
  input: AttachmentInput;
};


export type MutationModifyAttachmentArgs = {
  input: AttachmentInput;
};


export type MutationUpsertAttachmentArgs = {
  input: AttachmentInput;
};


export type MutationDeleteAttachmentFromRecordArgs = {
  input: AttachmentCrossReferenceInput;
};


export type MutationAddCommentArgs = {
  input: AddCommentInput;
};


export type MutationModifyCommentArgs = {
  input: ModifyCommentInput;
};


export type MutationDeleteCommentArgs = {
  commentId: Scalars['String'];
};


export type MutationAddDocumentationArgs = {
  input: DocumentationInput;
};


export type MutationModifyDocumentationArgs = {
  input: DocumentationInput;
};


export type MutationBulkModifyDocumentationsArgs = {
  input: BulkModifyDocumentationInput;
};


export type MutationUpsertDocumentationArgs = {
  input: DocumentationInput;
};


export type MutationDeleteDocumentationArgs = {
  documentationId: Scalars['String'];
};


export type MutationDeleteDocumentationsArgs = {
  documentationIds: Array<Scalars['String']>;
};


export type MutationAddDocumentRevisionArgs = {
  input: DocumentRevisionInput;
};


export type MutationModifyDocumentRevisionArgs = {
  input: DocumentRevisionInput;
};


export type MutationUpsertDocumentRevisionArgs = {
  input: DocumentRevisionInput;
};


export type MutationDeleteDocumentRevisionArgs = {
  documentRevisionId: Scalars['String'];
};


export type MutationAddEquipmentForInventoryArgs = {
  input: EquipmentForInventoryInput;
};


export type MutationRemoveEquipmentFromInventoryArgs = {
  input: EquipmentForInventoryInput;
};


export type MutationAddInventoryArgs = {
  input: InventoryInput;
};


export type MutationModifyInventoryArgs = {
  input: InventoryInput;
};


export type MutationUpsertInventoryArgs = {
  input: InventoryInput;
};


export type MutationDeleteInventoryArgs = {
  inventoryId: Scalars['String'];
};


export type MutationAddInventoryToLocationArgs = {
  input: InventoryLocationInput;
};


export type MutationModifyInventoryLocationArgs = {
  input: InventoryLocationInput;
};


export type MutationUpsertInventoryLocationArgs = {
  input: InventoryLocationInput;
};


export type MutationRemoveInventoryFromLocationArgs = {
  input: RemoveInventoryFromLocationInput;
};


export type MutationAddBarcodeArgs = {
  input: BarcodeInput;
};


export type MutationRemoveBarcodeArgs = {
  input: BarcodeInput;
};


export type MutationAddLogEntryArgs = {
  input: LogEntryInput;
};


export type MutationUpsertLogEntryArgs = {
  input: LogEntryInput;
};


export type MutationModifyLogEntryArgs = {
  input: LogEntryInput;
};


export type MutationDeleteLogEntryArgs = {
  logEntryId: Scalars['String'];
};


export type MutationDeleteLogEntriesArgs = {
  logEntryIds: Array<Scalars['String']>;
};


export type MutationAddInventoryToPurchaseOrderArgs = {
  input: AddInventoryToPurchaseOrderInput;
};


export type MutationAddSpareArgs = {
  input: SparesUsedInput;
};


export type MutationModifySpareArgs = {
  input: SparesUsedInput;
};


export type MutationUpsertSpareArgs = {
  input: SparesUsedInput;
};


export type MutationDeleteSpareArgs = {
  spareId: Scalars['String'];
};


export type MutationUpsertGridSavedViewArgs = {
  input: GridSavedViewInput;
};


export type MutationCreateGridSavedViewArgs = {
  input: GridSavedViewInput;
};


export type MutationModifyGridSavedViewArgs = {
  input: GridSavedViewInput;
};


export type MutationDeleteGridSavedViewArgs = {
  gridSavedViewId: Scalars['String'];
};

export type Order = {
  __typename?: 'Order';
  orderDate: Scalars['DateTime'];
  purchaseOrderNumber?: Maybe<Scalars['String']>;
  supplier?: Maybe<Scalars['String']>;
  department?: Maybe<Scalars['String']>;
  orderedBy?: Maybe<Scalars['String']>;
  contact?: Maybe<Scalars['String']>;
  noView?: Maybe<Scalars['Boolean']>;
  myDesc?: Maybe<Scalars['String']>;
  orderName?: Maybe<Scalars['String']>;
  orderSent?: Maybe<Scalars['Boolean']>;
  supplierId?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  promisedByDate?: Maybe<Scalars['DateTime']>;
  dateEntered?: Maybe<Scalars['DateTime']>;
  approvals?: Maybe<Scalars['String']>;
  html?: Maybe<Scalars['String']>;
  orderItems: Array<OrderItem>;
  id: Scalars['String'];
  categoryId?: Maybe<Scalars['String']>;
  category?: Maybe<Category>;
  photosCount?: Maybe<Scalars['Int']>;
  documentsCount?: Maybe<Scalars['Int']>;
  attachments: Array<Attachment>;
  commentsCount?: Maybe<Scalars['Int']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  updatedAt: Scalars['DateTime'];
  deletedBy?: Maybe<Scalars['String']>;
  isRecoverable?: Maybe<Scalars['Boolean']>;
};

export type OrderItem = {
  __typename?: 'OrderItem';
  id: Scalars['String'];
  inventoryId?: Maybe<Scalars['String']>;
  orderId: Scalars['String'];
  productName?: Maybe<Scalars['String']>;
  productDescription?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['Decimal']>;
  unitPrice?: Maybe<Scalars['Decimal']>;
  modelNumber?: Maybe<Scalars['String']>;
  department?: Maybe<Scalars['String']>;
  manufacturer?: Maybe<Scalars['String']>;
  qty?: Maybe<Scalars['String']>;
  book?: Maybe<Scalars['String']>;
  page?: Maybe<Scalars['String']>;
  figure?: Maybe<Scalars['String']>;
  toInventory?: Maybe<Scalars['Boolean']>;
  searchMod?: Maybe<Scalars['String']>;
  searchMaker?: Maybe<Scalars['String']>;
  searchPart?: Maybe<Scalars['String']>;
  partNumber?: Maybe<Scalars['String']>;
  amtReceived?: Maybe<Scalars['Decimal']>;
  eqDesc?: Maybe<Scalars['String']>;
  qcurrency?: Maybe<Scalars['String']>;
  qcurrencyAmt?: Maybe<Scalars['Decimal']>;
  qtyRecd?: Maybe<Scalars['String']>;
  orderComplete?: Maybe<Scalars['Byte']>;
  dataType?: Maybe<Scalars['String']>;
  size?: Maybe<Scalars['String']>;
  color?: Maybe<Scalars['String']>;
  dateEntered?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  updatedAt: Scalars['DateTime'];
  deletedBy?: Maybe<Scalars['String']>;
  isRecoverable?: Maybe<Scalars['Boolean']>;
};

export type Query = {
  __typename?: 'Query';
  appSettings: AppSettings;
  attachmentsForRecords: Array<Attachment>;
  attachmentsForRecord: Array<Attachment>;
  attachmentById?: Maybe<Attachment>;
  attachmentsForDocumentation: Array<Attachment>;
  categories: Array<Category>;
  commentsForRecord: Array<Comment>;
  companies: Array<Company>;
  companyNames: Array<CompanyName>;
  crewNames: Array<Crew>;
  currentUser: Crew;
  currencies: Array<Currency>;
  documentationById?: Maybe<Documentation>;
  documentationByDocTitle?: Maybe<Documentation>;
  documentationByCategory?: Maybe<Array<Documentation>>;
  documentation?: Maybe<DocumentationCollectionSegment>;
  documentationCountsByCategory: Array<GroupCountOfCategory>;
  documentRevisionsForDocumentation?: Maybe<Array<DocumentRevision>>;
  equipment?: Maybe<EquipmentCollectionSegment>;
  equipmentById?: Maybe<Equipment>;
  equipmentForInventory: Array<Equipment>;
  inventory?: Maybe<InventoryCollectionSegment>;
  inventoryForEquipment: Array<Inventory>;
  inventoryById?: Maybe<Inventory>;
  inventoryByBarcode: Array<Inventory>;
  inventoryLocations: Array<InventoryLocation>;
  inventoryAtLocation: Array<InventoryLocation>;
  inventoryCountsByCategory: Array<GroupCountOfCategory>;
  inventoryCountsByLocation: Array<GroupCountOfLocation>;
  list: Array<List>;
  locations: Array<Location>;
  logEntries?: Maybe<LogEntriesCollectionSegment>;
  logEntry?: Maybe<LogEntry>;
  logEntriesGroupedByEquipment?: Maybe<Array<GroupListOfEquipmentAndLogEntry>>;
  logEntryCountsByCategory: Array<GroupCountOfCategory>;
  logEntryCountsByLocation: Array<GroupCountOfLocation>;
  inventoryOrderHistory: Array<InventoryOrderHistory>;
  purchaseOrders: Array<Order>;
  equipmentSchedules?: Maybe<EquipmentSchedulesCollectionSegment>;
  eventSchedules?: Maybe<EventSchedulesCollectionSegment>;
  drillSchedules?: Maybe<DrillSchedulesCollectionSegment>;
  inspectionsSchedules?: Maybe<InspectionsSchedulesCollectionSegment>;
  sparesUsed: Array<SparesUsed>;
  gridSavedViews: Array<GridSavedView>;
};


export type QueryAttachmentsForRecordsArgs = {
  recordIds: Array<Scalars['String']>;
  attachmentType: AttachmentType;
  order?: Maybe<Array<AttachmentSortInput>>;
};


export type QueryAttachmentsForRecordArgs = {
  recordId: Scalars['String'];
  recordType: RecordType;
  attachmentType: AttachmentType;
};


export type QueryAttachmentByIdArgs = {
  attachmentId: Scalars['String'];
};


export type QueryAttachmentsForDocumentationArgs = {
  documentationId: Scalars['String'];
};


export type QueryCategoriesArgs = {
  filterInput?: Maybe<CategoryFilterInput>;
};


export type QueryCommentsForRecordArgs = {
  recordId: Scalars['String'];
  order?: Maybe<Array<CommentSortInput>>;
};


export type QueryCompaniesArgs = {
  where?: Maybe<CompanyFilterInput>;
  order?: Maybe<Array<CompanySortInput>>;
};


export type QueryCrewNamesArgs = {
  order?: Maybe<Array<CrewSortInput>>;
};


export type QueryCurrenciesArgs = {
  order?: Maybe<Array<CurrencySortInput>>;
};


export type QueryDocumentationByIdArgs = {
  documentationId: Scalars['String'];
};


export type QueryDocumentationByDocTitleArgs = {
  docTitle: Scalars['String'];
};


export type QueryDocumentationByCategoryArgs = {
  categoryId: Scalars['String'];
};


export type QueryDocumentationArgs = {
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  filterInput?: Maybe<DocumentationFilterInput>;
  order?: Maybe<Array<DocumentationSortInput>>;
};


export type QueryDocumentationCountsByCategoryArgs = {
  filterInput?: Maybe<DocumentationFilterInput>;
};


export type QueryDocumentRevisionsForDocumentationArgs = {
  documentationId: Scalars['String'];
};


export type QueryEquipmentArgs = {
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  filterInput?: Maybe<EquipmentFilterInput>;
  order?: Maybe<Array<EquipmentSortInput>>;
};


export type QueryEquipmentByIdArgs = {
  eqKey: Scalars['String'];
};


export type QueryEquipmentForInventoryArgs = {
  inventoryId: Scalars['String'];
};


export type QueryInventoryArgs = {
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  filterInput?: Maybe<InventoryFilterInput>;
  order?: Maybe<Array<InventorySortInput>>;
};


export type QueryInventoryForEquipmentArgs = {
  equipmentId: Scalars['String'];
};


export type QueryInventoryByIdArgs = {
  inventoryId: Scalars['String'];
};


export type QueryInventoryByBarcodeArgs = {
  barcode: Scalars['String'];
};


export type QueryInventoryLocationsArgs = {
  inventoryId: Scalars['String'];
};


export type QueryInventoryAtLocationArgs = {
  locationId?: Maybe<Scalars['String']>;
  filterInput?: Maybe<InventoryFilterInput>;
};


export type QueryInventoryCountsByCategoryArgs = {
  filterInput?: Maybe<InventoryFilterInput>;
};


export type QueryInventoryCountsByLocationArgs = {
  filterInput?: Maybe<InventoryFilterInput>;
};


export type QueryListArgs = {
  listName: Scalars['String'];
};


export type QueryLogEntriesArgs = {
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  filterInput?: Maybe<LogEntryFilterInput>;
  order?: Maybe<Array<LogEntrySortInput>>;
};


export type QueryLogEntryArgs = {
  logEntryId: Scalars['String'];
};


export type QueryLogEntriesGroupedByEquipmentArgs = {
  filterById?: Maybe<Scalars['String']>;
  filterBy: LogEntryFilterBy;
  filterInput: LogEntryFilterInput;
};


export type QueryLogEntryCountsByCategoryArgs = {
  filterInput?: Maybe<LogEntryFilterInput>;
};


export type QueryLogEntryCountsByLocationArgs = {
  filterInput?: Maybe<LogEntryFilterInput>;
};


export type QueryInventoryOrderHistoryArgs = {
  inventoryId: Scalars['String'];
};


export type QueryEquipmentSchedulesArgs = {
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  filterInput?: Maybe<EquipmentScheduleFilterInput>;
  order?: Maybe<Array<EquipmentScheduleSortInput>>;
};


export type QueryEventSchedulesArgs = {
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  filterInput?: Maybe<ScheduleFilterInput>;
  order?: Maybe<Array<ScheduleSortInput>>;
};


export type QueryDrillSchedulesArgs = {
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  filterInput?: Maybe<ScheduleFilterInput>;
  order?: Maybe<Array<ScheduleSortInput>>;
};


export type QueryInspectionsSchedulesArgs = {
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  filterInput?: Maybe<ScheduleFilterInput>;
  order?: Maybe<Array<ScheduleSortInput>>;
};


export type QuerySparesUsedArgs = {
  logKey: Scalars['String'];
};


export type QueryGridSavedViewsArgs = {
  moduleName: Scalars['String'];
};

export enum RecordType {
  LogEntry = 'LOG_ENTRY',
  Equipment = 'EQUIPMENT',
  WorkIssue = 'WORK_ISSUE',
  InventoryGeneral = 'INVENTORY_GENERAL',
  InventoryBeverages = 'INVENTORY_BEVERAGES',
  InventoryUniform = 'INVENTORY_UNIFORM',
  InventoryDryAndColdStore = 'INVENTORY_DRY_AND_COLD_STORE',
  InventoryMedical = 'INVENTORY_MEDICAL',
  EquipmentSchedule = 'EQUIPMENT_SCHEDULE',
  EventSchedule = 'EVENT_SCHEDULE',
  DrillSchedule = 'DRILL_SCHEDULE',
  InspectionSchedule = 'INSPECTION_SCHEDULE',
  PurchaseOrder = 'PURCHASE_ORDER',
  EngineersDayLog = 'ENGINEERS_DAY_LOG',
  RunningLog = 'RUNNING_LOG',
  Crew = 'CREW',
  Guest = 'GUEST',
  SmsReport = 'SMS_REPORT',
  IspsReport = 'ISPS_REPORT',
  VesselCertificates = 'VESSEL_CERTIFICATES',
  CrewCertificates = 'CREW_CERTIFICATES',
  DocumentationVesselReference = 'DOCUMENTATION_VESSEL_REFERENCE',
  DocumentationDrawings = 'DOCUMENTATION_DRAWINGS',
  DocumentationManuals = 'DOCUMENTATION_MANUALS',
  DocumentationMsds = 'DOCUMENTATION_MSDS',
  DocumentationPhotos = 'DOCUMENTATION_PHOTOS',
  DocumentationSms = 'DOCUMENTATION_SMS',
  DocumentationIsps = 'DOCUMENTATION_ISPS',
  HoursOfRest = 'HOURS_OF_REST',
  Undefined = 'UNDEFINED'
}

export type RemoveInventoryFromLocationInput = {
  inventoryId: Scalars['String'];
  locationId: Scalars['String'];
};

export type Schedule = {
  __typename?: 'Schedule';
  id: Scalars['String'];
  subject: Scalars['String'];
  notes?: Maybe<Scalars['String']>;
  hoursKey?: Maybe<Scalars['String']>;
  hourInterval?: Maybe<Scalars['Int']>;
  timeInterval?: Maybe<Scalars['Int']>;
  timeMeasure?: Maybe<Scalars['String']>;
  hourWarn?: Maybe<Scalars['Int']>;
  timeWarn?: Maybe<Scalars['Int']>;
  timeMeasureWarn?: Maybe<Scalars['String']>;
  steps?: Maybe<Scalars['String']>;
  sms?: Maybe<Scalars['Boolean']>;
  recurType?: Maybe<Scalars['Byte']>;
  assignedTo?: Maybe<Scalars['String']>;
  department?: Maybe<Scalars['String']>;
  recurInterval?: Maybe<Scalars['Int']>;
  recurPattern?: Maybe<Scalars['String']>;
  toolsReqd?: Maybe<Scalars['String']>;
  hourLock?: Maybe<Scalars['Boolean']>;
  ttlwork?: Maybe<Scalars['String']>;
  csm?: Maybe<Scalars['Boolean']>;
  schedType?: Maybe<Scalars['String']>;
  userDefined?: Maybe<Scalars['String']>;
  listType?: Maybe<Scalars['String']>;
  duration?: Maybe<Scalars['Int']>;
  dateEntered?: Maybe<Scalars['DateTime']>;
  isCheckList?: Maybe<Scalars['Boolean']>;
  linkId?: Maybe<Scalars['String']>;
  quotedPrice?: Maybe<Scalars['Decimal']>;
  quotedCurrency?: Maybe<Scalars['String']>;
  rtf?: Maybe<Scalars['String']>;
  plainText?: Maybe<Scalars['String']>;
  html?: Maybe<Scalars['String']>;
  categoryId?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  updatedAt: Scalars['DateTime'];
  deletedBy?: Maybe<Scalars['String']>;
  isRecoverable?: Maybe<Scalars['Boolean']>;
  equipmentId?: Maybe<Scalars['String']>;
  hoursTrigger?: Maybe<Scalars['Int']>;
  hoursCompleted?: Maybe<Scalars['Int']>;
  dateTrigger?: Maybe<Scalars['DateTime']>;
  runOnce?: Maybe<Scalars['Boolean']>;
  iterations?: Maybe<Scalars['Int']>;
  deferred?: Maybe<Scalars['Boolean']>;
  index?: Maybe<Scalars['Int']>;
  deferredDate?: Maybe<Scalars['DateTime']>;
  locationId?: Maybe<Scalars['String']>;
};

export type ScheduleFilterInput = {
  subject?: Maybe<Scalars['String']>;
  excludeIds?: Maybe<Array<Scalars['String']>>;
};

export type ScheduleSortInput = {
  id?: Maybe<SortEnumType>;
  subject?: Maybe<SortEnumType>;
  notes?: Maybe<SortEnumType>;
  hoursKey?: Maybe<SortEnumType>;
  hourInterval?: Maybe<SortEnumType>;
  timeInterval?: Maybe<SortEnumType>;
  timeMeasure?: Maybe<SortEnumType>;
  hourWarn?: Maybe<SortEnumType>;
  timeWarn?: Maybe<SortEnumType>;
  timeMeasureWarn?: Maybe<SortEnumType>;
  steps?: Maybe<SortEnumType>;
  sms?: Maybe<SortEnumType>;
  recurType?: Maybe<SortEnumType>;
  assignedTo?: Maybe<SortEnumType>;
  department?: Maybe<SortEnumType>;
  recurInterval?: Maybe<SortEnumType>;
  recurPattern?: Maybe<SortEnumType>;
  toolsReqd?: Maybe<SortEnumType>;
  hourLock?: Maybe<SortEnumType>;
  ttlwork?: Maybe<SortEnumType>;
  csm?: Maybe<SortEnumType>;
  schedType?: Maybe<SortEnumType>;
  userDefined?: Maybe<SortEnumType>;
  listType?: Maybe<SortEnumType>;
  duration?: Maybe<SortEnumType>;
  dateEntered?: Maybe<SortEnumType>;
  isCheckList?: Maybe<SortEnumType>;
  linkId?: Maybe<SortEnumType>;
  quotedPrice?: Maybe<SortEnumType>;
  quotedCurrency?: Maybe<SortEnumType>;
  rtf?: Maybe<SortEnumType>;
  plainText?: Maybe<SortEnumType>;
  html?: Maybe<SortEnumType>;
  categoryId?: Maybe<SortEnumType>;
  deletedAt?: Maybe<SortEnumType>;
  updatedAt?: Maybe<SortEnumType>;
  deletedBy?: Maybe<SortEnumType>;
  isRecoverable?: Maybe<SortEnumType>;
  equipmentId?: Maybe<SortEnumType>;
  hoursTrigger?: Maybe<SortEnumType>;
  hoursCompleted?: Maybe<SortEnumType>;
  dateTrigger?: Maybe<SortEnumType>;
  runOnce?: Maybe<SortEnumType>;
  iterations?: Maybe<SortEnumType>;
  deferred?: Maybe<SortEnumType>;
  index?: Maybe<SortEnumType>;
  deferredDate?: Maybe<SortEnumType>;
  locationId?: Maybe<SortEnumType>;
};

export enum SortEnumType {
  Asc = 'ASC',
  Desc = 'DESC'
}

export type SparePayload = {
  __typename?: 'SparePayload';
  spare?: Maybe<SparesUsed>;
  errors?: Maybe<Array<UserError>>;
};

export type SparesUsed = {
  __typename?: 'SparesUsed';
  id: Scalars['String'];
  inventoryId: Scalars['String'];
  logEntryId?: Maybe<Scalars['String']>;
  equipmentId?: Maybe<Scalars['String']>;
  workIssueId?: Maybe<Scalars['String']>;
  multipleLocationId?: Maybe<Scalars['String']>;
  qty?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['Decimal']>;
  dateUsed?: Maybe<Scalars['DateTime']>;
  dateEntered?: Maybe<Scalars['DateTime']>;
  updateInvOnSave?: Maybe<Scalars['Boolean']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  updatedAt: Scalars['DateTime'];
  deletedBy?: Maybe<Scalars['String']>;
  isRecoverable?: Maybe<Scalars['Boolean']>;
  inventory: Inventory;
};

export type SparesUsedInput = {
  id?: Maybe<Scalars['String']>;
  logEntryId: Scalars['String'];
  inventoryId: Scalars['String'];
  amount: Scalars['Int'];
};

export type StringOperationFilterInput = {
  and?: Maybe<Array<StringOperationFilterInput>>;
  or?: Maybe<Array<StringOperationFilterInput>>;
  eq?: Maybe<Scalars['String']>;
  neq?: Maybe<Scalars['String']>;
  contains?: Maybe<Scalars['String']>;
  ncontains?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
  startsWith?: Maybe<Scalars['String']>;
  nstartsWith?: Maybe<Scalars['String']>;
  endsWith?: Maybe<Scalars['String']>;
  nendsWith?: Maybe<Scalars['String']>;
};

export type UserError = {
  __typename?: 'UserError';
  message: Scalars['String'];
  code: Scalars['String'];
};

export type WorkIssue = {
  __typename?: 'WorkIssue';
  id: Scalars['String'];
  subject: Scalars['String'];
  equipmentId?: Maybe<Scalars['String']>;
  scheduleId?: Maybe<Scalars['String']>;
  smstype?: Maybe<Scalars['String']>;
  smsid?: Maybe<Scalars['String']>;
  equipment?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  deptCreator?: Maybe<Scalars['String']>;
  department?: Maybe<Scalars['String']>;
  company?: Maybe<Scalars['String']>;
  percentComplete?: Maybe<Scalars['Decimal']>;
  dateDue?: Maybe<Scalars['DateTime']>;
  dateStart?: Maybe<Scalars['DateTime']>;
  dateWarn?: Maybe<Scalars['DateTime']>;
  completed?: Maybe<Scalars['Boolean']>;
  dateCompleted?: Maybe<Scalars['DateTime']>;
  workItem?: Maybe<Scalars['Boolean']>;
  status?: Maybe<Scalars['String']>;
  priority?: Maybe<Scalars['String']>;
  assignedTo?: Maybe<Scalars['String']>;
  ttlwork?: Maybe<Scalars['String']>;
  actualWork?: Maybe<Scalars['String']>;
  logOnDone?: Maybe<Scalars['Boolean']>;
  dayChk?: Maybe<Scalars['Byte']>;
  isWarranty?: Maybe<Scalars['Boolean']>;
  workList?: Maybe<Scalars['String']>;
  hasBeenLogged?: Maybe<Scalars['Boolean']>;
  sms?: Maybe<Scalars['Boolean']>;
  quotedPrice?: Maybe<Scalars['Decimal']>;
  quotedCurrency?: Maybe<Scalars['String']>;
  actualCost?: Maybe<Scalars['Decimal']>;
  actualCurrency?: Maybe<Scalars['String']>;
  paid?: Maybe<Scalars['Boolean']>;
  hours?: Maybe<Scalars['Int']>;
  isCar?: Maybe<Scalars['Boolean']>;
  locationId?: Maybe<Scalars['String']>;
  dateEntered?: Maybe<Scalars['DateTime']>;
  parentId?: Maybe<Scalars['String']>;
  duration?: Maybe<Scalars['Int']>;
  isCheckList?: Maybe<Scalars['Boolean']>;
  custJob?: Maybe<Scalars['String']>;
  html?: Maybe<Scalars['String']>;
  categoryId?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  updatedAt: Scalars['DateTime'];
  deletedBy?: Maybe<Scalars['String']>;
  isRecoverable?: Maybe<Scalars['Boolean']>;
  checks?: Maybe<Scalars['String']>;
};

export type WorkIssueSortInput = {
  id?: Maybe<SortEnumType>;
  subject?: Maybe<SortEnumType>;
  equipmentId?: Maybe<SortEnumType>;
  scheduleId?: Maybe<SortEnumType>;
  smstype?: Maybe<SortEnumType>;
  smsid?: Maybe<SortEnumType>;
  equipment?: Maybe<SortEnumType>;
  notes?: Maybe<SortEnumType>;
  deptCreator?: Maybe<SortEnumType>;
  department?: Maybe<SortEnumType>;
  company?: Maybe<SortEnumType>;
  percentComplete?: Maybe<SortEnumType>;
  dateDue?: Maybe<SortEnumType>;
  dateStart?: Maybe<SortEnumType>;
  dateWarn?: Maybe<SortEnumType>;
  completed?: Maybe<SortEnumType>;
  dateCompleted?: Maybe<SortEnumType>;
  workItem?: Maybe<SortEnumType>;
  status?: Maybe<SortEnumType>;
  priority?: Maybe<SortEnumType>;
  assignedTo?: Maybe<SortEnumType>;
  ttlwork?: Maybe<SortEnumType>;
  actualWork?: Maybe<SortEnumType>;
  logOnDone?: Maybe<SortEnumType>;
  dayChk?: Maybe<SortEnumType>;
  isWarranty?: Maybe<SortEnumType>;
  workList?: Maybe<SortEnumType>;
  hasBeenLogged?: Maybe<SortEnumType>;
  sms?: Maybe<SortEnumType>;
  quotedPrice?: Maybe<SortEnumType>;
  quotedCurrency?: Maybe<SortEnumType>;
  actualCost?: Maybe<SortEnumType>;
  actualCurrency?: Maybe<SortEnumType>;
  paid?: Maybe<SortEnumType>;
  hours?: Maybe<SortEnumType>;
  isCar?: Maybe<SortEnumType>;
  locationId?: Maybe<SortEnumType>;
  dateEntered?: Maybe<SortEnumType>;
  parentId?: Maybe<SortEnumType>;
  duration?: Maybe<SortEnumType>;
  isCheckList?: Maybe<SortEnumType>;
  custJob?: Maybe<SortEnumType>;
  html?: Maybe<SortEnumType>;
  categoryId?: Maybe<SortEnumType>;
  deletedAt?: Maybe<SortEnumType>;
  updatedAt?: Maybe<SortEnumType>;
  deletedBy?: Maybe<SortEnumType>;
  isRecoverable?: Maybe<SortEnumType>;
  checks?: Maybe<SortEnumType>;
};
