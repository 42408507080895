import { gql, useQuery } from '@apollo/client';
import { Equipment } from 'src/generated/dotnet.graphql';
import { logger } from 'src/helpers/logger';

export const GET__EQUIPMENT_FOR_INVENTORY = gql`
  query GetEquipmentForInventory($inventoryId: String!) {
    equipmentForInventory(inventoryId: $inventoryId) {
      id
      uniqueName
      manufacturer
      modelNumber
    }
  }
`;

export interface EquipmentForInventoryResult {
  equipmentForInventory: Equipment[];
  equipmentForInventoryRefetch: any;
  equipmentForInventoryLoading: boolean;
  equipmentForInventoryCount: number
}

export const useGetEquipmentForInventory = (inventoryId: string): EquipmentForInventoryResult => {
  const { data, loading, error, refetch } = useQuery(GET__EQUIPMENT_FOR_INVENTORY, {
    variables: { inventoryId },
    skip: !inventoryId,
    notifyOnNetworkStatusChange: true,
  });

  if (error) {
    logger('GetEquipmentForInventory').error(`Error fetching equipment for inventory: ${inventoryId} -->`, error.message);
  }

  return {
    equipmentForInventory: data?.equipmentForInventory || [],
    equipmentForInventoryRefetch: refetch,
    equipmentForInventoryLoading: loading,
    equipmentForInventoryCount: data?.equipmentForInventory.length, 
  };
};
