import { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import { BlobProvider } from "@react-pdf/renderer";
import CloseIcon from "@mui/icons-material/Close";
import CircularProgress from '@mui/material/CircularProgress';
import { AppBar, Toolbar, IconButton } from "@mui/material";
import PSPDFKit from "../../../PrintButton/PSPDFKit";
import PSPDFKitPrint from "./PSPDFKitPrint";

type PSPDFKitViewerProps = {
    generatePDF: () => JSX.Element;
    enable: boolean;
    reportType: string;
    onClose?: () => void;
};

const PSPDFKitViewer: React.ForwardRefRenderFunction<any, PSPDFKitViewerProps> = ({ generatePDF, enable, onClose, reportType }, ref) => {
    const [isPdfReady, setIsPdfReady] = useState(false);
    const [open, setOpen] = useState(false);
    const [document, setDocument] = useState<any>();
    const createPDF = async () => {
        setDocument(generatePDF());
        setIsPdfReady(true);
    }

    const handlePSPDFKitOpen = () => {
        setOpen(true);
    };
  
    const handlePSPDFKitClose = () => {
        onClose && onClose(); // when close PDF, ReportPopup gets closed also
        setOpen(false);
    };

    // Expose method to parent component using useImperativeHandle
    useImperativeHandle(ref, () => ({
        handlePSPDFKitOpen,
    }));

    useEffect(() => {
        if (enable && open) {
            createPDF();
        }
    }, [enable, open]);

    return (
        <div>
            <Dialog
                fullScreen={true}
                open={open}
                onClose={handlePSPDFKitClose}
                aria-labelledby="responsive-dialog-title"
            >
                <AppBar sx={{ position: "relative" }}>
                <Toolbar style={{ display: "flex", justifyContent: "space-between" }}>
                        <div>{reportType}</div>
                        <IconButton
                            edge="end"
                            color="inherit"
                            onClick={handlePSPDFKitClose}
                            aria-label="close"
                        >
                            <CloseIcon />
                        </IconButton>
                    </Toolbar>
                </AppBar>
                <DialogContent className="p-0">
                    {isPdfReady && 
                        <BlobProvider document={document}>
                            {({ blob, url, loading, error }) => {
                                console.log({ blob, url, loading, error });
                                if (blob) {
                                    if(reportType === "Barcode") {
                                        return <PSPDFKitPrint blob={blob} />
                                    } else {
                                        return <PSPDFKit blob={blob} />;
                                    }
                                }

                                if (error) {
                                    return error;
                                }

                                return <div>The PDF is rendering...</div>;
                            }}
                        </BlobProvider>
                    }
                    {!isPdfReady && <div className="flex justify-center	items-center w-full h-screen">
                        <CircularProgress />
                    </div>}
                </DialogContent>
            </Dialog>
        </div>
    );
}

// Enabled forwardRef
const ForwardedPSPDFKitViewer = forwardRef(PSPDFKitViewer);

export default ForwardedPSPDFKitViewer;
