import { gql, useQuery } from '@apollo/client';
import { InventoryOrderHistory } from 'src/generated/dotnet.graphql';
import { logger } from 'src/helpers/logger';

export const GET__INVENTORY_ORDER_HISTORY = gql`
  query GetInventoryOrdersHistory($inventoryId: String!) {
    inventoryOrderHistory(inventoryId: $inventoryId) {
      id
      inventoryId
      orderDate
      orderName
      status
      amountOrdered
      amountReceived
    }
  }
`;

export interface InventoryOrderHistoryResult {
  inventoryOrderHistory: InventoryOrderHistory[];
  inventoryOrderHistoryLoading: boolean;
}

export const useGetInventoryOrderHistory = (inventoryId: string): InventoryOrderHistoryResult => {
  const { data, loading, error } = useQuery(GET__INVENTORY_ORDER_HISTORY, {
    variables: { inventoryId },
    skip: !inventoryId,
    notifyOnNetworkStatusChange: true,
  });

  if (error) {
    logger('GetInventoryOrdersHistory').error(`Error fetching orders containing inventory: ${inventoryId} -->`, error.message);
  }

  return {
    inventoryOrderHistory: data?.inventoryOrderHistory || [],
    inventoryOrderHistoryLoading: loading,
  };
};
