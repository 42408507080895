import '../styles.css';
import { useCallback, useState, MutableRefObject, useRef, useEffect, forwardRef, useImperativeHandle } from 'react';
import { isNil, pick, isEmpty, isBoolean } from 'lodash';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material';
import {exportCSV, exportExcel} from '../../../utils';
import LicensedReactDataGrid from '../../../components/UI/LicensedReactDataGrid';
import { TypeComputedProps, TypeFilterValue, TypeSingleSortInfo, TypeSortInfo } from '@inovua/reactdatagrid-enterprise/types';
import { SortEnumType, Inventory, InventoryFilterInput, InventorySortInput, InventoryType } from 'src/generated/dotnet.graphql';
import GridRowIcon from 'src/components/UI/LicensedReactDataGrid/components/GridRowIcon';
import { ExportDialogType } from 'src/consts';
import { useGetLazyInventory } from 'src/hooks/inventory/useGetLazyInventory';
import { useGetInventory } from 'src/hooks/inventory/useGetInventory';

const filter = [
  {
    name: 'productName',
    operator: 'contains',
    type: 'string',
    value: '',
  },
  {
    name: 'manufacturer',
    operator: 'contains',
    type: 'string',
    value: '',
  },
  {
    name: 'partNumber',
    operator: 'contains',
    type: 'select',
    value: '',
  },
  {
    name: 'modelNumber',
    operator: 'contains',
    type: 'select',
    value: '',
  },
  {
    name: 'country',
    operator: 'contains',
    type: 'select',
    value: '',
  },
  {
    name: 'region',
    operator: 'contains',
    type: 'select',
    value: '',
  },
  {
    name: 'color',
    operator: 'contains',
    type: 'select',
    value: '',
  },
  {
    name: 'size',
    operator: 'contains',
    type: 'select',
    value: '',
  },
  {
    name: 'department',
    operator: 'contains',
    type: 'string',
    value: '',
  },
];

const generalInventoryColumns = [
  {
    id: 'icons',
    header: 'Icons',
    defaultWidth: 135,
    render: ({ data }: any) => {
      return (
      <GridRowIcon 
        hasAttachments={data.documentsCount > 0} 
        hasPhotos={data.photosCount > 0} 
        hasComments={data.commentsCount > 0} 
        isCritical={data.sms}
        expiredAtLocation={data.expiredAtLocation}
        lowStock={data.lowStock}
      />
    )},
    sortable: false
  },
  {
    name: 'productName',
    header: 'Product Name',
    flex: 2,
  },
  {
    name: 'manufacturer',
    header: 'Manufacturer',
    flex: 1,
  },
  {
    name: 'partNumber',
    header: 'Part Number',
    flex: 1,
  },
  {
    name: 'modelNumber',
    header: 'Model Number',
    flex: 1,
  },
  {
    name: 'department',
    header: 'Department',
    flex: 1,
  },
  {
    name: 'totalAmount',
    header: 'Total Amount',
    flex: 1,
  }
];

const beveragesColumns = [
  {
    id: 'icons',
    header: 'Icons',
    defaultWidth: 115,
    render: ({ data }: any) => {
      return (
      <GridRowIcon 
        hasAttachments={data.documentsCount > 0} 
        hasPhotos={data.photosCount > 0} 
        hasComments={data.commentsCount > 0} 
        expiredAtLocation={data.expiredAtLocation}
        lowStock={data.lowStock}
      />
    )},
    sortable: false
  },
  {
    name: 'productName',
    header: 'Product Name',
    flex: 2,
  },
  {
    name: 'manufacturer',
    header: 'Maker/Bottler',
    flex: 1,
  },
  {
    name: 'partNumber',
    header: 'Varietal',
    flex: 1,
  },
  {
    name: 'modelNumber',
    header: 'Vintage',
    flex: 1,
  },
  {
    name: 'country',
    header: 'Country',
    flex: 1,
  },
  {
    name: 'region',
    header: 'Region',
    flex: 1,
  },
  {
    name: 'color',
    header: 'Color/Type (Wine)',
    flex: 1,
  },
  {
    name: 'size',
    header: 'Size',
    flex: 1,
  },
  {
    name: 'totalAmount',
    header: 'Total Amount',
    flex: 1,
  }
];

const storesColumns = [
  {
    id: 'icons',
    header: 'Icons',
    defaultWidth: 115,
    render: ({ data }: any) => {
      return (
      <GridRowIcon 
        hasAttachments={data.documentsCount > 0} 
        hasPhotos={data.photosCount > 0} 
        hasComments={data.commentsCount > 0} 
        expiredAtLocation={data.expiredAtLocation}
        lowStock={data.lowStock}
      />
    )},
    sortable: false
  },
  {
    name: 'productName',
    header: 'Product Name',
    flex: 2,
  },
  {
    name: 'manufacturer',
    header: 'Manufacturer',
    flex: 1,
  },
  {
    name: 'modelNumber',
    header: 'Product Number',
    flex: 1,
  },
  {
    name: 'totalAmount',
    header: 'Total Amount',
    flex: 1,
  }
];

const uniformColumns = [
  {
    id: 'icons',
    header: 'Icons',
    defaultWidth: 115,
    render: ({ data }: any) => {
      return (
      <GridRowIcon 
        hasAttachments={data.documentsCount > 0} 
        hasPhotos={data.photosCount > 0} 
        hasComments={data.commentsCount > 0} 
        expiredAtLocation={data.expiredAtLocation}
        lowStock={data.lowStock}
      />
    )},
    sortable: false
  },
  {
    name: 'productName',
    header: 'Product Name',
    flex: 2,
  },
  {
    name: 'manufacturer',
    header: 'Manufacturer',
    flex: 1,
  },
  {
    name: 'modelNumber',
    header: 'Style',
    flex: 1,
  },
  {
    name: 'color',
    header: 'Color',
    flex: 1,
  },
  {
    name: 'size',
    header: 'Size',
    flex: 1,
  },
  {
    name: 'totalAmount',
    header: 'Total Amount',
    flex: 1,
  }
];

const medicalColumns = [
  {
    id: 'icons',
    header: 'Icons',
    defaultWidth: 115,
    render: ({ data }: any) => {
      return (
      <GridRowIcon 
        hasAttachments={data.documentsCount > 0} 
        hasPhotos={data.photosCount > 0} 
        hasComments={data.commentsCount > 0} 
        expiredAtLocation={data.expiredAtLocation}
        lowStock={data.lowStock}
      />
    )},
    sortable: false
  },
  {
    name: 'productName',
    header: 'Product Name',
    flex: 2,
  },
  {
    name: 'manufacturer',
    header: 'Manufacturer',
    flex: 1,
  },
  {
    name: 'modelNumber',
    header: 'Item Number',
    flex: 1,
  },
  {
    name: 'totalAmount',
    header: 'Total Amount',
    flex: 1,
  }
];

const getSelectorByFilterName = async (
  name: string,
  value: any,
): Promise<Record<string, any>> => {
  switch (name) {
    case 'productName':
    case 'manufacturer':
    case 'partNumber':
    case 'modelNumber':
    case 'country':
    case 'region':
    case 'color':
    case 'size':
    case 'department': {
      return {
        [name]: value,
      };
    }
    default:
      return {};
  }
};

const transformData = (data: any[]): Record<string, Inventory> => {
  return data.reduce((acc, { pkey, ...rest }) => {
    acc[pkey] = rest;
    return acc;
  }, {});
}

type InventoryGridProps = {
  darken: boolean;
  refetchTree: boolean;
  onSelect: (item: Inventory) => void;
  inventoryType: InventoryType;
  skip: number;
  setSkip: (skip: number) => void;
  limit: number;
  setLimit: (limit: number) => void;
  filterValue: InventoryFilterInput;
  setFilterValue: (filterValue: InventoryFilterInput) => void;
  sortValue: InventorySortInput[];
  setSortValue: (sortValue: InventorySortInput[]) => void;
  rowSelected: Record<string, Inventory>;
  setRowSelected: (rowSelected: Record<string, Inventory>) => void;
};

interface InventoryGridRef {
  handleExport: (type: string) => void;
}

const InventoryGrid = forwardRef<InventoryGridRef, InventoryGridProps>(({ 
  onSelect, 
  refetchTree,
  inventoryType,
  skip,
  setSkip,
  limit,
  setLimit,
  filterValue,
  setFilterValue,
  sortValue,
  setSortValue,
  rowSelected,
  setRowSelected, 
}: InventoryGridProps, ref) => {
  const [gridRef, setGridRef] = useState<any>(null);
  const [exportDialog, setExportDialog] = useState<ExportDialogType | null>(null);
  const [columns, setColumns] = useState<any>([]);
  const { getLazyInventory } = useGetLazyInventory(filterValue, sortValue);
  const { data, totalCount, loading, loadData } = useGetInventory(skip, limit, filterValue, sortValue);

  const prevFilterValue = useRef(filterValue);
  const prevSkip = useRef(skip);
  const prevLimit = useRef(limit);
  const prevSortValue = useRef(sortValue);

  useImperativeHandle(ref, () => ({
    handleExport: (type) => {
      handleDataExport(type);
    },
  }));

  const initializeColumns = () => {
    switch (inventoryType) {
      case InventoryType.GeneralInventory:
        setColumns(generalInventoryColumns);
        break;
      case InventoryType.Beverages:
        setColumns(beveragesColumns);
        break;
      case InventoryType.DryAndColdStore:
        setColumns(storesColumns);
        break;     
      case InventoryType.Uniform:
        setColumns(uniformColumns);
        break;      
      case InventoryType.Medical:
        setColumns(medicalColumns);
        break;      
      default:
        setColumns([]);
    }
  };

  useEffect(() => {
    initializeColumns();
  }, [inventoryType]);

  useEffect(() => {
    const isFilterChanged = JSON.stringify(filterValue) !== JSON.stringify(prevFilterValue.current);
    const isPaginationChanged = skip !== prevSkip.current || limit !== prevLimit.current;
    const isSortChanged = JSON.stringify(sortValue) !== JSON.stringify(prevSortValue.current);
  
    if (isFilterChanged || isPaginationChanged || isSortChanged) {
      loadData(skip, limit, filterValue, sortValue);
      prevFilterValue.current = filterValue;
      prevSkip.current = skip;
      prevLimit.current = limit;
      prevSortValue.current = sortValue;
    } else {
      loadData(skip, limit, filterValue, sortValue); // this is trigerred on storage amount update
    }
  }, [skip, limit, filterValue, sortValue, refetchTree]);
    
  const dataSource = async () => {
    return {
      data,
      count: totalCount,
    };
  };

  const onSortInfoChange = (value: TypeSortInfo) => {
    const sortInfo = value as TypeSingleSortInfo
    if (isNil(sortInfo)) return;

    let sortPayload: InventorySortInput[];
    const sortDirection = sortInfo.dir === 1 ? SortEnumType.Asc : SortEnumType.Desc;
    const [field, subField] = sortInfo.name.split('.');

    if (subField) {
        // Handle nested objects
        sortPayload = [{
            [field]: {
                [subField]: sortDirection,
            },
        }];
    } else {
        // Handle non-nested objects
        sortPayload = [{
            [sortInfo.name]: sortDirection,
        }];
    }
    setSortValue(sortPayload)
  }

  const onFilterValueChange = async (filterValue: TypeFilterValue) => {
    if (isNil(filterValue)) return;

    const filterPayload: Record<string, any> = {};

    await Promise.all(
      filterValue.map(async (v: { value: any; name: any; operator: any }) => {
        if (isEmpty(v.value)) return;

        const selector = await getSelectorByFilterName(v.name, v.value);
        if (selector) {
          Object.keys(selector).forEach((key) => {
            filterPayload[key] = selector[key];
          });
        }
      })
    );

    // const defaultFilterValue = {
    //   inventoryType: inventoryType as InventoryType,
    //   serachText: ''
    // }

    // Merge with the existing filterValue
    setFilterValue({
      // ...defaultFilterValue,
      ...filterPayload,
    });
  };

  const onSelectionChange = useCallback(({ selected }) => {
    if (isBoolean(selected) && selected) {
      const transformedData = transformData(data);
      setRowSelected(transformedData)
    } else {
      setRowSelected(selected);
    }
  }, [data, rowSelected]);

  const onRowClick = useCallback(
    ({ data }) => {
      if (!data?.__group) {
        if (Object.keys(rowSelected || {}).length < 2) {
          onSelect(data);
        }
      }
    },
    [rowSelected]
  );

  const onReady = (ref: MutableRefObject<TypeComputedProps | null>) => {
    setGridRef(ref);
  };

  // Find if any filters applied to grid
  const areFiltersActive = gridRef?.current.computedFilterValue.some((f: { value: any; }) => !isEmpty(f.value));

  const exportData = (type: string, withFilters = true) => {
    setExportDialog(null);
    switch (type) {
      case 'CSV':
        return onExportToCSV(withFilters);
      case 'xlsx':
        return onExportToExcel(withFilters);
      default:
    }
  };

  const getRows = async (issue: any) => {
    const rows =  issue.map((data: any) =>{
      return {
        ...pick(data, [
          'productName',
          'manufacturer',
          'partNumber',
          'modelNumber',
          'reOrderLevel',
          'department'
        ]),
      };
    }   
    );
    return rows
  };

  const onExportToExcel = async (withFilters: boolean) => {
    const queryResult = await getLazyInventory({ variables: { where: withFilters ? filterValue : null, order: sortValue } });
    const data = queryResult?.data?.inventory || [];
    const columnsData = gridRef.current.visibleColumns.map((c: any) => ({
      header: c.header,
      key: c.id,
    }));
    const columns = columnsData.filter((item: { header: any; }) => {
      return item.header && typeof item.header === 'string';
    });
    const rows = await getRows(data)
    return exportExcel(columns, rows);
  };

  const onExportToCSV = async (withFilters: boolean) => {
    const columns = gridRef.current.visibleColumns;
    const queryResult = await getLazyInventory({ variables: { where: withFilters ? filterValue : null, order: sortValue } });
    const data = queryResult?.data?.inventory || [];    
    const rows = await getRows(data)
    return exportCSV(columns, rows);
  };

  const handleDataExport = (type: string) => {
    if (areFiltersActive) {
      setExportDialog({
        visible: true,
        type,
        title: type === 'CSV' ? 'CSV' : 'Excel',
      });

      return;
    }
    exportData(type, false);
  };

  return (
    <div data-testid="data-grid" className="flex flex-col flex-grow">
      <LicensedReactDataGrid
        onReady={onReady}
        loading={loading}
        columns={columns}
        pagination='remote'
        idProperty='id'
        dataSource={dataSource}
        enableSelection
        checkboxColumn
        skip={skip}
        onSkipChange={setSkip}
        limit={limit}
        onLimitChange={setLimit}
        onRowClick={onRowClick}
        selected={rowSelected}
        onSelectionChange={onSelectionChange}
        onSortInfoChange={onSortInfoChange}
        defaultFilterValue={filter}
        onFilterValueChange={onFilterValueChange}
        disableGroupByToolbar
        sourceRoot={true}
        enableColumnFilterContextMenu={false}
        // pageSizes={[10, 50, 100, 500, 1000, totalCount]}
      />
{/* {inventoryType === InventoryType.GeneralInventory && <LicensedReactDataGrid
        onReady={onReady}
        loading={loading}
        columns={generalInventoryColumns}
        pagination='remote'
        idProperty='id'
        dataSource={dataSource}
        enableSelection
        checkboxColumn
        skip={skip}
        onSkipChange={setSkip}
        limit={limit}
        onLimitChange={setLimit}
        onRowClick={onRowClick}
        selected={rowSelected}
        onSelectionChange={onSelectionChange}
        onSortInfoChange={onSortInfoChange}
        defaultFilterValue={filter}
        onFilterValueChange={onFilterValueChange}
        disableGroupByToolbar
        sourceRoot={true}
        enableColumnFilterContextMenu={false}
        // pageSizes={[10, 50, 100, 500, 1000, totalCount]}
      />}
{inventoryType === InventoryType.Beverages && <LicensedReactDataGrid
        onReady={onReady}
        loading={loading}
        columns={beveragesColumns}
        pagination='remote'
        idProperty='id'
        dataSource={dataSource}
        enableSelection
        checkboxColumn
        skip={skip}
        onSkipChange={setSkip}
        limit={limit}
        onLimitChange={setLimit}
        onRowClick={onRowClick}
        selected={rowSelected}
        onSelectionChange={onSelectionChange}
        onSortInfoChange={onSortInfoChange}
        defaultFilterValue={filter}
        onFilterValueChange={onFilterValueChange}
        disableGroupByToolbar
        sourceRoot={true}
        enableColumnFilterContextMenu={false}
        // pageSizes={[10, 50, 100, 500, 1000, totalCount]}
      />}
{inventoryType === InventoryType.Uniform && <LicensedReactDataGrid
        onReady={onReady}
        loading={loading}
        columns={uniformColumns}
        pagination='remote'
        idProperty='id'
        dataSource={dataSource}
        enableSelection
        checkboxColumn
        skip={skip}
        onSkipChange={setSkip}
        limit={limit}
        onLimitChange={setLimit}
        onRowClick={onRowClick}
        selected={rowSelected}
        onSelectionChange={onSelectionChange}
        onSortInfoChange={onSortInfoChange}
        defaultFilterValue={filter}
        onFilterValueChange={onFilterValueChange}
        disableGroupByToolbar
        sourceRoot={true}
        enableColumnFilterContextMenu={false}
        // pageSizes={[10, 50, 100, 500, 1000, totalCount]}
      />}
{inventoryType === InventoryType.DryAndColdStore && <LicensedReactDataGrid
        onReady={onReady}
        loading={loading}
        columns={storesColumns}
        pagination='remote'
        idProperty='id'
        dataSource={dataSource}
        enableSelection
        checkboxColumn
        skip={skip}
        onSkipChange={setSkip}
        limit={limit}
        onLimitChange={setLimit}
        onRowClick={onRowClick}
        selected={rowSelected}
        onSelectionChange={onSelectionChange}
        onSortInfoChange={onSortInfoChange}
        defaultFilterValue={filter}
        onFilterValueChange={onFilterValueChange}
        disableGroupByToolbar
        sourceRoot={true}
        enableColumnFilterContextMenu={false}
        // pageSizes={[10, 50, 100, 500, 1000, totalCount]}
      />}
{inventoryType === InventoryType.Medical && <LicensedReactDataGrid
        onReady={onReady}
        loading={loading}
        columns={medicalColumns}
        pagination='remote'
        idProperty='id'
        dataSource={dataSource}
        enableSelection
        checkboxColumn
        skip={skip}
        onSkipChange={setSkip}
        limit={limit}
        onLimitChange={setLimit}
        onRowClick={onRowClick}
        selected={rowSelected}
        onSelectionChange={onSelectionChange}
        onSortInfoChange={onSortInfoChange}
        defaultFilterValue={filter}
        onFilterValueChange={onFilterValueChange}
        disableGroupByToolbar
        sourceRoot={true}
        enableColumnFilterContextMenu={false}
        // pageSizes={[10, 50, 100, 500, 1000, totalCount]}
      />} */}

      

      <Dialog maxWidth="xs" open={exportDialog?.visible || false}>
        <DialogTitle>
          Export data to
          {exportDialog?.title}
        </DialogTitle>
        <DialogContent dividers>
          <Typography gutterBottom>
            You have filters applied. Would you like to export with current
            filters?
          </Typography>
        </DialogContent>
        <DialogActions sx={{ justifyContent: 'flex-end' }}>
          <Button
            autoFocus
            onClick={() => exportDialog && exportData(exportDialog?.type, false)}
          >
            No
          </Button>
          <Button
            variant="contained"
            onClick={() => exportDialog && exportData(exportDialog?.type, true)}
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </div>
    );
  }
);

export default InventoryGrid;
