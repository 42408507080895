import { gql } from '@apollo/client';

export const UpdateLogEntryDocumentsCountCache = gql`
  fragment UpdateLogEntryDocumentsCountCache on LogEntry {
    documentsCount
  }
`;
export const UpdateInventoryDocumentsCountCache = gql`
  fragment UpdateInventoryDocumentsCountCache on Inventory {
    documentsCount
  }
`;
export const UpdateLogEntryPhotosCountCache = gql`
  fragment UpdateLogEntryPhotosCountCache on LogEntry {
    photosCount
  }
`;
export const UpdateInventoryPhotosCountCache = gql`
  fragment UpdateInventoryPhotosCountCache on Inventory {
    photosCount
  }
`;
export const UpdateLogEntryCommentsCountCache = gql`
  fragment UpdateLogEntryCommentsCountCache on LogEntry {
    commentsCount
  }
`;
export const UpdateDocumentationCommentsCountCache = gql`
  fragment UpdateDocumentationCommentsCountCache on Documentation {
    commentsCount
  }
`;
export const UpdateInventoryCommentsCountCache = gql`
  fragment UpdateInventoryCommentsCountCache on Inventory {
    commentsCount
  }
`;
export const UpdateLogEntrySparesUsedCountCache = gql`
  fragment UpdateLogEntrySparesUsedCountCache on LogEntry {
    sparesUsedCount
  }
`;
export const UpdateInventorySpareForEquipmentCache = gql`
  fragment UpdateInventorySpareForEquipmentCache on Inventory {
    equipmentCount
  }
`;