import { gql, LazyQueryExecFunction, OperationVariables, useLazyQuery  } from "@apollo/client";
import {InventoryCollectionSegment } from "src/generated/dotnet.graphql";
import { logger } from "src/helpers/logger";

export const GET_LAZY_INVENTORY_BY_CATEGORY = gql`
  query GetLazyInventoryByCategory($filterInput: InventoryFilterInput) {
    inventory(filterInput: $filterInput) {
      items {
        id
        productName
        manufacturer
        partNumber
        modelNumber
        department
        consumable
        bonded
        sms
        country
        region
        color
        size
        reOrderLevel
        orderQty
        totalAmount
        lowStock
        expiredAtLocation
      }
    }
  }
`

interface InventoryData {
  inventory: InventoryCollectionSegment;
}

interface InventoryResult {
  getLazyInventoryByCategory: LazyQueryExecFunction<InventoryData, OperationVariables>;
}

export const useGetLazyInventoryByCategory = (): InventoryResult => {

  const [ getInventory, { error }] = useLazyQuery(GET_LAZY_INVENTORY_BY_CATEGORY, {
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
  });
  
  if (error) {
    logger('GetLazyInventoryByCategory').error('Error fetching inventory by category -->', error.message);
  }

  return {
    getLazyInventoryByCategory: getInventory
  };
};