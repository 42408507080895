import { FC, useState } from 'react';
import { Button, DialogTitle, DialogActions, DialogContent, Box } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import SpareForEquipmentAddEquipmentGrid from './SpareForEquipmentAddEquipmentGrid';
import { CustomDialog } from 'src/helpers/modals';

interface InjectedProps {
  excludedIds: string[];
  visible: boolean;
  onSave: (equipmentIds: string[]) => void;
  onCancel?: () => void;
}

const SpareForEquipmentAddDialog: FC<InjectedProps> = ({
  excludedIds,
  visible,
  onCancel,
  onSave,
}) => {
  const [processing, setProcessing] = useState<boolean>(false);
  const [selectedEquipmentIds, setSelectedEquipmentIds] = useState<string[]>([]);

  const handleSave = async () => {
    if (!selectedEquipmentIds) return;
    onSave(selectedEquipmentIds);
  };

  const handleCancel = () => {
    onCancel && onCancel();
    setProcessing(false);
  };

  const handleSelect = (equipmentIds: string[]) => {
    setSelectedEquipmentIds(equipmentIds);
  };

  return (
    <>
      <CustomDialog
        scroll="paper"
        fullWidth
        maxWidth="md"
        open={visible}
        onClose={handleCancel}
      >
        <DialogTitle>
          <span className="font-bold text-2xl">Add Equipment</span>
        </DialogTitle>
        <DialogContent dividers sx={{ p: 0 }}>
          <SpareForEquipmentAddEquipmentGrid
            excludedIds={excludedIds}
            onSelect={handleSelect}
          />
        </DialogContent>
        <DialogActions sx={{ px: 4, pb: 4, justifyContent: 'space-between' }}>
          <Box
            sx={{ justifyContent: 'flex-end', flexGrow: 1, display: 'flex' }}
          >
            <Button className="mr-2" onClick={handleCancel}>
              Cancel
            </Button>
            <LoadingButton
              loading={processing}
              type="submit"
              onClick={handleSave}
              variant="contained"
            >
              Save
            </LoadingButton>
          </Box>
        </DialogActions>
      </CustomDialog>
    </>
  );
};

export default SpareForEquipmentAddDialog;
