import './style.css'
import { FC } from 'react';
import moment from 'moment';
import { isNil } from 'lodash';
import { useMediaQuery } from '@mui/material';
import LicensedReactDataGrid from 'src/components/UI/LicensedReactDataGrid';
import { useGetInventoryOrderHistory } from 'src/hooks/inventory/useGetInventoryOrderHistory';

interface Props {
  inventoryId: string;
}

const OrderHistoryTab: FC<Props> = ({ inventoryId }) => {
  const { inventoryOrderHistory, inventoryOrderHistoryLoading } = useGetInventoryOrderHistory(inventoryId);
  const isTablet = useMediaQuery('(min-width: 700px) and (max-width: 1200px)');
  const isMobile = useMediaQuery('(max-width: 420px)');

  const columns = [
    {
      name: 'orderDate',
      header: 'Order Date',
      dateFormat: 'DD-MMM-YYYY',
      defaultFlex: 0.75,
      visible: !(isTablet || isMobile),
      render: ({ value, cellProps: { dateFormat } }: any) => {
        if (!isNil(value)) {
          return moment(value).format(dateFormat);
        }
      },
    },
    {
      name: 'orderName',
      header: 'Order Name',
      defaultFlex: 0.75,
      visible: !isMobile,
    },
    {
      name: 'status',
      header: 'Order Status',
      defaultFlex: 0.75,
      visible: !isMobile,
    },
    {
      name: 'amountOrdered',
      header: 'Ordered',
      defaultFlex: 0.5,
      headerAlign: 'start' as any,
      textAlign: 'end' as any,
    },
    {
      name: 'amountReceived',
      header: 'Received',
      defaultFlex: 0.5,
      headerAlign: 'start' as any,
      textAlign: 'end' as any,
    },
  ];

  return (
    <>
      <div className="pt-5">
        <div data-testid="data-grid" className="flex flex-col flex-grow inv-order-history">
          <LicensedReactDataGrid
            rowHeight={40}
            loading={inventoryOrderHistoryLoading}
            showColumnMenuTool={false}
            idProperty="id"
            columns={columns} 
            dataSource={inventoryOrderHistory}
            defaultSortInfo={[
              {name: 'orderDate', dir: -1}
            ]}
          />
        </div>
      </div>
    </>
  );
};

export default OrderHistoryTab;
