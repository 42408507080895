import { useState, FC } from 'react';
import { Alert, AlertColor, Box, Card, CardContent, CardHeader, CircularProgress, Divider, Grid, IconButton, Snackbar, Typography } from '@mui/material';
import { DeleteTwoTone, Edit } from '@mui/icons-material';
import WarningDialog from 'src/components/UI/WarningDialog';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import RemoveRoundedIcon from '@mui/icons-material/RemoveRounded';
import { Inventory, InventoryLocation, RemoveInventoryFromLocationInput } from 'src/generated/dotnet.graphql';
import { useGetInventoryLocations } from 'src/hooks/inventory/useGetInventoryLocation';
import { useDeleteInventoryLocation } from 'src/hooks/inventory/useDeleteInventoryLocation';
import { SEVERITY } from 'src/consts';
import { useUpdateInventoryLocationAmount } from 'src/hooks/inventory/useUpdateInventoryLocationAmount';
import { isNotNil } from 'src/utils';

interface Props {
  inventory: Inventory;
  onDelete: () => void;
  onSelect: (item: InventoryLocation) => void;
  refreshGridView: () => void;
  readOnly: boolean;
}

const StorageCardsGrid: FC<Props> = ({
  inventory,
  onDelete,
  onSelect,
  refreshGridView,
  readOnly = false,
}) => {

  const [isDeleting, setIsDeleting] = useState(false);
  const [deleteSelected, setDeleteSelected] = useState<InventoryLocation>();
  const [snackbar, setSnackbar] = useState<{ message: string; severity: AlertColor }>();
  const { inventoryLocations, inventoryLocationsLoading } = useGetInventoryLocations(inventory.id);
  const { removeInventoryLocation } = useDeleteInventoryLocation();
  const { updateInventoryLocationAmount } = useUpdateInventoryLocationAmount();
  
  const onEditClick = (item: InventoryLocation) => {
    onSelect(item);
  };

  const onDeleteClick = (item: InventoryLocation) => {
    setDeleteSelected(item);
    setIsDeleting(true);
  };

  const handleOkDelete = async () => {
    if (!deleteSelected?.locationId) return; // remove this line after dete mutation accepts locationId=null
    const payload: RemoveInventoryFromLocationInput = {
      inventoryId: inventory.id,
      locationId: deleteSelected.locationId
      // locationId: item.locationId ?? null
    }

    const { responseData, responseMessage } = await removeInventoryLocation(payload);
  
    if (responseData?.success) {
      onDelete();
      refreshGridView();
      handleCancelDelete();
    }
  
    setSnackbar({
      message: responseMessage,
      severity: responseData ? SEVERITY.SUCCESS : SEVERITY.ERROR,
    });
  };

  const handleCancelDelete = () => {
    setIsDeleting(false);
    setDeleteSelected(undefined);
  };

  const handleAmountChange = (inventoryLocation: InventoryLocation, increase: boolean) => {
    updateInventoryLocationAmount(inventoryLocation, increase);
    refreshGridView();
  }
    
  const CommonGridUI = (title: string, value: any) => (
    <>
      <Grid item xs={3}>
        <Typography sx={{ fontSize: 12 }}>{title}</Typography>
      </Grid>
      <Grid item xs={9}>
        <Typography
          sx={{ fontSize: 12, fontWeight: 700 }}
          className="flex justify-start"
        >
          {isNotNil(value) ? value : 'Not Specified'}
        </Typography>
      </Grid>
    </>
  );

  return (
    <>
      {inventoryLocationsLoading ? (
        <div className="flex items-center justify-center w-full">
          <CircularProgress />
        </div>
        ) : (
          <Grid
            container
            spacing={{ xs: 2, md: 3 }}
            columns={{ xs: 2, sm: 4, md: 12 }}
          >
          {inventoryLocations?.map((inventoryLocation) => (
            <Grid item xs={2} sm={4} md={4} key={inventoryLocation.id}>
              <Card elevation={3} sx={{ height: 135 }}>
                <CardContent sx={{ padding: 2 }}>
                  <CardHeader
                    sx={{
                      padding: 1,
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center'
                    }}
                    title={
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <IconButton
                          onClick={() => handleAmountChange(inventoryLocation, true)}
                          size="small"
                          aria-label="Increase item amount"
                          disabled={readOnly}
                        >
                          <AddRoundedIcon fontSize="inherit" color={readOnly ? 'disabled' : 'primary'}/>
                        </IconButton>
                        <IconButton
                          onClick={() => handleAmountChange(inventoryLocation, false)}
                          size="small"
                          aria-label="Decrease item amount"
                          disabled={readOnly || inventoryLocation.amount <= 0}
                        >
                          <RemoveRoundedIcon fontSize="inherit" color={readOnly || inventoryLocation.amount <= 0 ? 'disabled' : 'error'} />
                        </IconButton>
                      </div>
                    }
                    action={
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <IconButton
                          onClick={() => onEditClick(inventoryLocation)}
                          size="small"
                          aria-label="Edit item"
                          disabled={readOnly}
                        >
                          <Edit fontSize="inherit" color={readOnly ? 'disabled' : "primary"} />
                        </IconButton>
                        <IconButton
                          color="error"
                          aria-label="Delete task"
                          className="ml-2"
                          size="small"
                          onClick={() => onDeleteClick(inventoryLocation)}
                          disabled={readOnly}
                        >
                          <DeleteTwoTone fontSize="inherit" color={readOnly ? 'disabled' : 'error'} />
                        </IconButton>
                      </div>
                    }
                  />
                  <Divider className="mb-3" />
                  <Box sx={{ width: '100%' }}>  
                    <Grid container rowSpacing={2} columnSpacing={2}>
                      {CommonGridUI('Location:', inventoryLocation.locationPath)}
                    </Grid>
                  </Box>
                  <Box sx={{ width: '100%' }}>
                    <Grid container rowSpacing={2} columnSpacing={2}>
                      {CommonGridUI('Amount:', inventoryLocation.amount ? inventoryLocation.amount : 0)}
                    </Grid>
                  </Box>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      )}   

      <WarningDialog
        visible={isDeleting}
        title="Delete Warning"
        content="Are you sure you wish to delete record?"
        okText="Yes"
        color="error"
        onOk={handleOkDelete}
        onCancel={handleCancelDelete}
      />

      <Snackbar
        open={!!snackbar}
        autoHideDuration={2000}
        onClose={() => setSnackbar(undefined)}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert severity={snackbar?.severity}>{snackbar?.message}</Alert>
      </Snackbar>
    </>
  );
};

export default StorageCardsGrid;