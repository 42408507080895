import { FC } from 'react';
import { Control } from 'react-hook-form';
import { Accordion, AccordionDetails, AccordionSummary, FormGroup } from '@mui/material';
import { ExpandMore } from '@mui/icons-material';
import Checkbox from 'src/components/UI/Forms/Checkbox';
import Input from 'src/components/UI/Forms/Input';
import RichTextEditor from '../../../UI/Forms/RichTextEditor';
import { handleCharLimitWarning, toJSON } from '../../../../utils';
import { useAppState } from 'src/contexts/app-state';
import { CHAR_LIMIT } from 'src/consts';
import CategoryDropdown from 'src/components/Dropdowns/CategoryDropdown';
import { Inventory, InventoryType, RecordType } from 'src/generated/dotnet.graphql';
import ListDefaultAutocomplete from 'src/components/UI/Forms/ListDefaultAutocomplete';
import StorageLocation from './StorageLocation';

interface InventorySummaryFormProps {
  inventory: Inventory;
  recordType: RecordType;
  inventoryType: InventoryType;
  onChange: (field: string, value: any) => void;
  refreshGridView: () => void;
  control: Control;
  isCreate: boolean;
  readOnly? : boolean;
}

const InventorySummaryForm: FC<InventorySummaryFormProps> = ({
  inventory,
  recordType,
  inventoryType,
  onChange,
  refreshGridView,
  control,
  isCreate,
  readOnly = false,
}) => {
  const { settingsPersonal } = useAppState();
  const { INVENTORY } = CHAR_LIMIT;

  const modelNumberLabel = inventoryType === InventoryType.Beverages ? 'Vintage' : inventoryType === InventoryType.DryAndColdStore ? 'Product Number' : 'Model Number'
  
  return (
    <div className="mt-3">
      <Accordion defaultExpanded>
        <AccordionSummary
          expandIcon={<ExpandMore />}
          aria-controls="general-information"
          id="general-information"
        >
          General Information
        </AccordionSummary>
        <AccordionDetails>
          <div className="w-full flex flex-col">
            <div className="mt-3">
              <CategoryDropdown
                name="categoryId"
                label="Category"
                control={control}
                onChange={onChange}
                recordType={recordType}
                rules={{ required: true }}
                allDepts={settingsPersonal?.fldAllDepts > 0}
              />
            </div>

            {inventoryType === InventoryType.Beverages &&
              <>
                <div className="pt-4">
                  <ListDefaultAutocomplete
                    disabled={readOnly}
                    onChange={onChange}
                    control={control}
                    name="country"
                    label="Country"
                    listName="Countries"
                    freeSolo={true}
                  />
                </div>
                <div className="pt-4">
                  <ListDefaultAutocomplete
                    disabled={readOnly}
                    onChange={onChange}
                    control={control}
                    name="region"
                    label="Region"
                    listName="Regions"
                    freeSolo={true}
                  />
                </div>
              </>
            }

            {inventoryType !== InventoryType.Uniform && inventoryType !== InventoryType.Medical &&
              <div className="mt-3">
                <Input
                  name="modelNumber"
                  rules={{ maxLength: INVENTORY.modelNumber }}
                  warning={(value) => handleCharLimitWarning(value, INVENTORY.modelNumber)}
                  control={control}
                  inputProps={{ label: modelNumberLabel }}
                />
              </div>
            }
            
            {inventoryType === InventoryType.GeneralInventory && 
              <div className="mt-3">
                <ListDefaultAutocomplete
                  disabled={readOnly}
                  onChange={onChange}
                  control={control}
                  name="department"
                  label="Department"
                  listName="Department"
                />
              </div>
            }

            {inventoryType === InventoryType.Uniform &&
              <div className="pt-4">
                <ListDefaultAutocomplete
                  disabled={readOnly}
                  onChange={onChange}
                  control={control}
                  name="color"
                  label="Color"
                  listName="Colors"
                  freeSolo={true}
                />
              </div>
            }

            {(inventoryType === InventoryType.Beverages || inventoryType === InventoryType.Uniform) && 
              <div className="pt-4">
                <ListDefaultAutocomplete
                  disabled={readOnly}
                  onChange={onChange}
                  control={control}
                  name="size"
                  label="Size"
                  listName="Sizes"
                  freeSolo={true}
                />
              </div>
            }

            <div className="mt-3">
              <FormGroup row>
                {inventoryType === InventoryType.GeneralInventory &&
                  <Checkbox
                   control={control}
                   name="consumable"
                   label="Consumable"
                 />             
                }
                <Checkbox
                  control={control}
                  name="bonded"
                  label="Bonded"
                />
                {inventoryType === InventoryType.GeneralInventory &&
                  <Checkbox
                    control={control}
                    name="sms"
                    label="Critical Inventory"
                  />
                }
              </FormGroup>
            </div>
          </div>

          <div className="mt-3">
            <RichTextEditor
              control={control}
              name="productDescription"
              onChange={onChange}
            />
          </div>
        </AccordionDetails>
      </Accordion>

      <Accordion defaultExpanded>
        <AccordionSummary
          expandIcon={<ExpandMore />}
          aria-controls="Storage Locations"
          id="storageLocations"
        >
          Storage Locations
        </AccordionSummary>
        <AccordionDetails>
          <div>
            <StorageLocation
              inventory={inventory}
              recordType={recordType}
              isCreate = {isCreate}
              refreshGridView={refreshGridView}
              readOnly={readOnly}
            />
          </div>
        </AccordionDetails>
      </Accordion>

      <Accordion className="mt-3">
        <AccordionSummary
          expandIcon={<ExpandMore />}
          aria-controls="Ordering Details"
          id="orderingDetails"
        >
          Ordering Details
        </AccordionSummary>
        <AccordionDetails>
          <div className="pt-4">
            <div className="flex flex-wrap">
              <div className="flex-grow p-2">
                <Input
                  name="reOrderLevel"
                  inputProps={{
                    label: 'Min. Holding Level',
                    type: 'number',
                    inputProps: { min: 0, style: { textAlign: 'end' } },
                  }}
                  control={control}
                  rules={{ min: 0 }}
                />
              </div>
              <div className="flex-grow p-2">
                <Input
                  name="reOrderAmt"
                  inputProps={{
                    label: 'Max. Holding Level',
                    type: 'number',
                    inputProps: { min: 0, style: { textAlign: 'end' } },
                  }}
                  control={control}
                  rules={{ min: 0 }}
                />
              </div>
            </div>

            <div className="flex flex-wrap">
              <div className="flex-grow p-2">
                <ListDefaultAutocomplete
                  disabled={readOnly}
                  onChange={onChange}
                  control={control}
                  name="qty"
                  label="Usage Measure"
                  listName="Qty"
                />
              </div>
              <div className="flex-grow p-2">
                <ListDefaultAutocomplete
                  disabled={readOnly}
                  onChange={onChange}
                  control={control}
                  name="orderQty"
                  label="Order Measure"
                  listName="Qty"
                />
              </div>
            </div>
            <div className="p-2 w-1/2">
              <Input
                name="factor"
                inputProps={{
                  label: 'Measure Factor',
                  type: 'number',
                  inputProps: {
                    inputMode: 'decimal',
                    pattern: '([0-9]+)?[,\\.]?[0-9]*',
                    step: 0.25,
                    style: { textAlign: 'end' },
                  },
                }}
                control={control}
                rules={{ min: 0 }}
              />
            </div>
          </div>
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

export default InventorySummaryForm;
