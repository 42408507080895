import { ReactNode, useEffect, useRef, useState } from 'react';
import TreeView from '@mui/lab/TreeView';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import TreeItem from '@mui/lab/TreeItem';
import { TextField, Button, TextFieldPropsSizeOverrides, Popper, ClickAwayListener, useMediaQuery } from '@mui/material'
import { getNodeList } from './utils';
import '../../theme/styles.css';
import { OverridableStringUnion } from '@mui/types';

interface Props {
  placeholder: any;
  data: any[];
  value: any;
  onValueChanged: (e: any) => void;
  keyExpr: string;
  valueExpr?: string;
  parentIdExpr?: string;
  displayExpr: string;
  disabled?: boolean;
  children?: ReactNode;
  error?: any;
  validationStatus?: 'valid' | 'invalid' | 'pending' | undefined;
  inputRef?: any;
  size?: OverridableStringUnion<'small' | 'medium', TextFieldPropsSizeOverrides>;
  dropdownHeight?: number;
}

const CardViewGenericTreeViewDropdown = ({
  data,
  value = [],
  onValueChanged,
  keyExpr,
  parentIdExpr,
  displayExpr,
  disabled,
  placeholder,
  children,
  error,
  inputRef,
  validationStatus = 'valid',
  size = 'small',
  dropdownHeight=240,
  ...rest
}: Props) => {
  let flag = true;
  const [treeBoxValue, setTreeBoxValue] = useState(value);
  const [hierachyData, setHierachyData] = useState([])
  const [listMapData, setListMapData] = useState<any>({});
  const [isOpen, setIsOpen] = useState(false);
  const [nodeIds, setNodeIds] = useState([]);
  const anchorRef = useRef<HTMLDivElement>(null);
  const isMobile = useMediaQuery('(max-width: 450px)');

  useEffect(() => {
    handleParseData(data);
  }, [data])

  useEffect(() => {
    if (value && !Array.isArray(value) && hierachyData.length > 0) {
      const nodeKeyList = getNodeList([listMapData[value]]);
      setTreeBoxValue(nodeKeyList)
    }
  }, [value]);

  function handleParseData(list: any) {
    let map: any = {}, node, roots: any = [], index, mapData: any = {};
    const listData = list.map((item: any) => ({
      key: item[keyExpr],
      label: item[displayExpr || 'category'],
      parentId: item[parentIdExpr || 'parentId'],
      parent: null,
      children: []
    }))

    for (index = 0; index < listData.length; index += 1) {
      map[listData[index].key] = index;
      mapData[listData[index].key] = listData[index];
    }

    for (index = 0; index < listData.length; index += 1) {
      node = listData[index];
      if (node.parentId) {
        if (map[node.parentId] >=0) {
          listData[map[node.parentId]].children.push(node);
          node.parent = listData[map[node.parentId]];
        }
      } else {
        roots.push(node);
      }
    }

    setHierachyData(roots)
    setListMapData(mapData);

    if (value && !Array.isArray(value)) {
      const nodeKeyList = getNodeList([mapData[value]]);
      setTreeBoxValue(nodeKeyList)
    }
  }

  const handleClickItem = (key: any) => {
    if (flag) {
      const nodeKeyList = getNodeList([key]);
      onValueChanged({ value: nodeKeyList })
      setTreeBoxValue(nodeKeyList)
      setIsOpen(false)
    }
    flag = true;
  }

  const handleClear = () => {
    onValueChanged({ value: [] })
    setTreeBoxValue([])
  }

  const handleClick = (event: any) => {
    setIsOpen(true);
  };

  const handleClose = () => {
    setIsOpen(false)
  };

  const getDisplayValue = (value: any) => {
    if (Array.isArray(value)) {
      return value.map((item: any) => listMapData[item]?.label || item).join(" >> ");
    } else {
      return listMapData[value]?.label || value;
    }
  }

  const renderTreeView = (treeItems: any) => {
    return treeItems.map((treeItemData: any) => {
      let children = undefined;
      if (treeItemData.children && treeItemData.children.length > 0) {
        children = renderTreeView(treeItemData.children);
      }
      return (
        <TreeItem
          key={treeItemData.key}
          nodeId={treeItemData.key}
          label={treeItemData.label}
          onClick={() => handleClickItem(treeItemData)}
          children={children}
        />
      );
    });
  };

  const handleClickExpand = (evt: any) => {
    flag = false;
  }

  const displayValue = getDisplayValue(treeBoxValue)

  return <div ref={anchorRef}>
    <TextField
      id="outlined-basic"
      disabled={disabled}
      fullWidth
      label={placeholder}
      variant="outlined"
      value={displayValue || ""}
      onClick={handleClick}
      error={!!error}
      inputRef={inputRef}
      autoComplete="off"
      InputProps={{
        readOnly: true,
      }}
      size={size}
      style={{
        width: isMobile ? '300px' : '400px', // Set the desired width for the input (narrower)
      }}
    />
      <Popper
        open={isOpen}
        anchorEl={anchorRef.current}
        placement="bottom"
        modifiers={[
          {
            name: 'offset',
            options: {
              offset: [0, 7], // Adjust the 8 to increase/decrease space between input and dropdown
            },
          },
        ]}
        style={{
          zIndex: 1300,
          width: isMobile ? '300px' : '400px', // Set the desired width for the dropdown card (wider)
          boxShadow: '0px 6px 24px rgba(0, 0, 0, 0.2)', // Increase shadow depth
          backgroundColor: 'white', // Ensures consistent dropdown background
          borderRadius: '8px', // Rounded corners
        }}
      >
        <ClickAwayListener onClickAway={handleClose}>
          <div
            style={{
              maxHeight: dropdownHeight,
              overflowY: 'auto',
              padding: '8px',
              backgroundColor: 'white',
              borderRadius: '4px',
            }}
          >
          <TreeView
            aria-label="file system navigator"
            defaultCollapseIcon={<ExpandMoreIcon onClick={handleClickExpand} />}
            defaultExpandIcon={<ChevronRightIcon onClick={handleClickExpand} />}
            onNodeToggle={(evt: any, nodeids: any) => setNodeIds(nodeids)}
            expanded={nodeIds}
          >
            {renderTreeView(hierachyData)}
          </TreeView>
        </div>
      </ClickAwayListener>
    </Popper>
  </div>
};

export default CardViewGenericTreeViewDropdown;