import { gql, LazyQueryExecFunction, OperationVariables, useLazyQuery } from "@apollo/client";
import { Inventory } from "src/generated/dotnet.graphql";
import { logger } from "src/helpers/logger";

export const GET_LAZY_INVENTORY_BY_ID = gql`
  query GetLazyInventoryById($inventoryId: String!) {
    inventoryById(inventoryId: $inventoryId) {
      id
      productName
      manufacturer
      partNumber
      modelNumber
      department
      categoryId
      consumable
      bonded
      sms
      country
      region
      color
      size
      reOrderLevel
      orderQty
      totalAmount
      documentsCount
      photosCount
      commentsCount
      equipmentCount
      ordersCount
    }
  }
`

export interface InventoryByIdData {
  inventoryById: Inventory;
};

interface InventoryByIdResult {
  getLazyInventoryById: LazyQueryExecFunction<InventoryByIdData, OperationVariables>;
}

export const useGetLazyInventoryById = (): InventoryByIdResult => {

  const [ getInventoryById, { error }] = useLazyQuery(GET_LAZY_INVENTORY_BY_ID, {
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
  });
  
  if (error) {
    logger('GetLazyInventoryById').error('Error fetching inventory -->', error.message);
  }

  return {
    getLazyInventoryById: getInventoryById
  };
};
  