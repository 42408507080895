import React from 'react';
import pdf from '@react-pdf/renderer';
const {
  Document,
  Page,
  View,
  StyleSheet,
  Font,
  Text,
  Image,
} = pdf;

// YSM Imported components
import { generateBarcodeImage } from './utils';

// YMS Components
import LuerssenLabel from './llFormat';

// Register the font files
Font.register({
  family: 'RobotoMono',
  fonts: [
    { src: `./fonts/RobotoMono-Regular.ttf` },
    { src: `./fonts/RobotoMono-Bold.ttf`, fontWeight: 'bold' }
  ],
});

// Styles for the barcode container
const styles = StyleSheet.create({
  page: {
    flexDirection: 'column',
    margin: 0,
    padding: 10,
    paddingBottom: 7,
  },

  labelsClass: {
    fontFamily: 'RobotoMono',
    fontWeight: 'bold',
  },

  dataText: {
    fontFamily: 'RobotoMono',
  },

  footer: {
    width: '99%',
    position: 'absolute',
    fontSize: 4,
    bottom: 0,
    left: 0,
    right: 0,
    textAlign: 'right',
  },
});

//NOTE: call sizes in points - 1" = 72pts
type LabelSizeProps = {
  width: number;
  height: number;
};

// Define the component
const BarcodeLabel = ( BCData: any) => {

  const labelSize = BCData.labelSize;
  const pageSize = {
    width: labelSize.width || 72,
    height: labelSize.height || 216,
  };

  const scaling = {
    fontSize: 8,
    marginTop: 5,
    barCodeHeight: 15,
    labelColumnWidth: 25,
    srhFontSize: 8,
    showLL: false,
    showImage: false,
    compact: false,
  };

  const setScaling = () => {
    if (labelSize.width === 144 && labelSize.height === 288) { // 2x4
      scaling.fontSize = 10;
      scaling.labelColumnWidth = 32;
      scaling.srhFontSize = 8;
      scaling.barCodeHeight = 25;
      return;
    } else if (labelSize.width === 144 && labelSize.height === 216) { // 2x3
      scaling.fontSize = 10;
      scaling.labelColumnWidth = 32;
      scaling.srhFontSize = 8;
      scaling.barCodeHeight = 25;
      return;
    } else if (labelSize.width === 216 && labelSize.height === 216) { // 3x3
      scaling.fontSize = 10;
      scaling.labelColumnWidth = 32;
      scaling.barCodeHeight = 50;
      return;
    } else if (labelSize.width === 216 && labelSize.height === 288) { // 3x4
      scaling.fontSize = 10;
      scaling.labelColumnWidth = 32;
      scaling.srhFontSize = 10;
      scaling.barCodeHeight = 50;
      return;
    } else if (labelSize.width === 90 && labelSize.height === 162) { // 1.25x2.25
      scaling.fontSize = 6;
      scaling.labelColumnWidth = 20;
      scaling.srhFontSize = 6;
      scaling.compact = true;
      return;
    } else if (labelSize.width === 72 && labelSize.height === 216) { // 1x3
      scaling.fontSize = 6;
      scaling.labelColumnWidth = 20;
      scaling.srhFontSize = 6;
      scaling.compact = true;
    } else if (labelSize.width === 90 && labelSize.height === 252) { // 1.25x3.5
      scaling.compact = true;
      return;
    } else if (labelSize.width <= 72) {
      scaling.compact = true;
      scaling.srhFontSize = 6;
    }
  };

  setScaling();

  return (
    <Document>
      <Page orientation='landscape' size={pageSize} style={styles.page}>
        {/* Container View */}
        <View
          style={{
            height: '100%',
            flexDirection: 'column',
            // justifyContent: 'space-between',
          }}
        >
          {/* Luerssen Data, PNO goes before Product on a LL */}
          {BCData.luerssenStyle && (
            <LuerssenLabel
              BCData={BCData}
              fontSize={scaling.fontSize}
              labelColumnWidth={scaling.labelColumnWidth}
              width={labelSize.height}
            />
          )}
          {/* END LL LABEL HEADER */}

          {/* Non-Luerssen Format */}
          {!BCData.luerssenStyle && (
            <View>
              {/* ProductName row */}
              <View style={{ flexDirection: 'row' }}>
                <Text
                  style={{
                    ...styles.labelsClass,
                    fontSize: scaling.fontSize,
                    width: scaling.labelColumnWidth,
                  }}
                >
                  Name:
                </Text>
                <Text
                  style={{
                    ...styles.dataText,
                    fontSize: scaling.fontSize,
                    width: '90%',
                  }}
                >
                  {BCData.productName}
                </Text>
              </View>

              {/* Manufacturer row */}
              <View style={{ flexDirection: 'row', marginTop: 3 }}>
                <Text
                  style={{
                    ...styles.labelsClass,
                    fontSize: scaling.fontSize,
                    width: scaling.labelColumnWidth,
                  }}
                >
                  Man:
                </Text>
                <Text
                  style={{
                    ...styles.dataText,
                    fontSize: scaling.fontSize,
                    width: '90%',
                  }}
                >
                  {BCData.manufacturer}
                </Text>
              </View>

              {/* Part Number & Model Number rows NOT FOR LL */}
              <View>
                <View style={{ flexDirection: 'row', marginTop: 3 }}>
                  <Text
                    style={{
                      ...styles.labelsClass,
                      fontSize: scaling.fontSize,
                      width: scaling.labelColumnWidth,
                    }}
                  >
                    PNO:
                  </Text>

                  <Text
                    style={{
                      ...styles.dataText,
                      fontSize: scaling.fontSize,
                      width: '90%',
                    }}
                  >
                    {BCData.partNumber}
                  </Text>
                </View>

                {!scaling.compact && (
                  <View style={{ flexDirection: 'row', marginTop: 3 }}>
                    <Text
                      style={{
                        ...styles.labelsClass,
                        fontSize: scaling.fontSize,
                        width: scaling.labelColumnWidth,
                      }}
                    >
                      MOD:
                    </Text>
                    <Text
                      style={{
                        ...styles.dataText,
                        fontSize: scaling.fontSize,
                        width: '90%',
                      }}
                    >
                      {BCData.modelNumber}
                    </Text>
                  </View>
                )}
              </View>

              {/* Category Row */}
              {!scaling.compact && (
                <View style={{ flexDirection: 'row', marginTop: 3 }}>
                  <Text
                    style={{ fontSize: scaling.srhFontSize, width: '100%' }}
                  >
                    {BCData.categoryFullPath}
                  </Text>
                </View>
              )}

              {/* Location Row */}
              <View style={{ marginTop: 3 }}>
                <Text
                  style={{
                    fontSize: scaling.srhFontSize,
                    width: '100%',
                    marginBottom: 2,
                  }}
                >
                  {BCData.locationFullPath}
                </Text>
              </View>
            </View>
          )}

          <View>
            <Image
              style={{ maxHeight: scaling.barCodeHeight }}
              src={generateBarcodeImage(
                BCData.barcode,
                labelSize.height,
                scaling.barCodeHeight
              )}
            />
          </View>
        </View>

        <Text
          style={{ ...styles.footer, marginBottom: 3 }}
          render={() => {
            const currentDate = new Date();
            const options: Intl.DateTimeFormatOptions = {
              month: '2-digit',
              year: 'numeric',
            };
            const formattedDate = currentDate.toLocaleString('en-US', options);
            return `${formattedDate}`;
          }}
          fixed
        />
      </Page>
    </Document>
  );
};

// Export the component
export default BarcodeLabel;