import './style.css';
import { FC, useState, useRef, useEffect, Dispatch, SetStateAction } from 'react';
import LicensedReactDataGrid from 'src/components/UI/LicensedReactDataGrid';
import { Alert, Snackbar, IconButton, Button, useMediaQuery, AlertColor } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import DeleteTwoToneIcon from '@mui/icons-material/DeleteTwoTone';
import EditIcon from '@mui/icons-material/Edit';
import { useGetEquipmentForInventory } from 'src/hooks/inventory/useGetEquipmentForInventory';
import { useAddEquipmentForInventory } from 'src/hooks/inventory/useAddEquipmentForInventory';
import { useDeleteEquipmentFromInventory } from 'src/hooks/inventory/useDeleteEquipmentForInventory';
import { Equipment, EquipmentForInventoryInput } from 'src/generated/dotnet.graphql';
import SpareForEquipmentAddDialog from './SpareForEquipmentAddDialog';
import SpareForEquipmentEditDialog from './SpareForEquipmentEditDialog';
import { SEVERITY } from 'src/consts';
import WarningDialog from 'src/components/UI/WarningDialog';

interface Props {
  inventoryId: string;
  setEquipmentCount: Dispatch<SetStateAction<number>>;
  disableEdit: boolean;
}

const SpareForEquipmentTab: FC<Props> = ({ 
  inventoryId, 
  setEquipmentCount, 
  disableEdit 
}) => {
  const isMobile = useMediaQuery('(max-width: 420px)')
  const { equipmentForInventory, equipmentForInventoryLoading } = useGetEquipmentForInventory(inventoryId);
  const { addEquipmentForInventory } = useAddEquipmentForInventory();
  const { removeEquipmentForInventory } = useDeleteEquipmentFromInventory();
  const [addPopupVisible, setAddPopupVisible] = useState<boolean>(false);
  const [editPopupVisible, setEditPopupVisible] = useState<boolean>(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [deleteSelected, setDeleteSelected] = useState<Equipment>();
  const [snackbar, setSnackbar] = useState<{ message: string; severity: AlertColor }>();

  // TODO - refactor to dotnet types when refactoring Equipment module
  // const [selectedEquipment, setSelectedEquipment] = useState<EquipmentDocument>(); 

  const prevRelatedItemsRef = useRef<Equipment[]>([]);
  const [excludedIds, setExcludedIds] = useState<string[]>([]);
 
  useEffect(() => {
    const onEquipmentForInventoryFetch = () => {
      const excludedIds = equipmentForInventory
        .map((equipment) => equipment.id)
        .filter((id) => id !== undefined) as string[];
        
      // Only update if `equipmentForInventory` has changed
      if (JSON.stringify(equipmentForInventory) !== JSON.stringify(prevRelatedItemsRef.current)) {
        setExcludedIds(excludedIds);
        prevRelatedItemsRef.current = equipmentForInventory;
      }
    };
    onEquipmentForInventoryFetch();
  }, [equipmentForInventory]);

  const handleAddClick = () => {
    setAddPopupVisible(true);
  };

  const handleAddCancel = () => {
    setAddPopupVisible(false);
  };

  const handleAddSave = async (equipmentIds: string[]) => {
    const { responseData, responseMessage} = await addEquipmentForInventory(equipmentIds, inventoryId);

    if(responseData) {
      setEquipmentCount((prevCount: number) => prevCount + equipmentIds.length);
      setAddPopupVisible(false);
    }

    setSnackbar({
      message: responseMessage,
      severity: responseData ? SEVERITY.SUCCESS : SEVERITY.ERROR,
    });
  };

  const onDeleteClick = (equipment: Equipment) => {
    setDeleteSelected(equipment);
    setIsDeleting(true);
  };

  const handleDeleteOk = () =>{
    if(!deleteSelected) return;
    handleDelete(deleteSelected);
    setIsDeleting(false);
    setDeleteSelected(undefined);
  };

  const handleDeleteCancel = () =>{
    setIsDeleting(false);
    setDeleteSelected(undefined);
  };

  const handleDelete = async (equipment: Equipment) => {
    const equipmentIds = [equipment.id];
    const { responseData, responseMessage} = await removeEquipmentForInventory(equipmentIds, inventoryId);

    if(responseData) {
      setEquipmentCount((prevCount: number) => prevCount - equipmentIds.length);
    }

    setSnackbar({
      message: responseMessage,
      severity: responseData ? SEVERITY.SUCCESS : SEVERITY.ERROR,
    });
  };

  // TODO - remove rxdb when Equipment module gets refactored
  // const onEditClick = async (item: any) => {
  //   const db = await getDatabase();
  //   const equipmentItem: any = await db.equipment
  //     .find({
  //       selector: {
  //         EqKey: item.id,
  //       },
  //     })
  //     .exec();
  //   setSelectedEquipment(equipmentItem[0]);
  //   setEditPopupVisible(true);
  // };

  // const handleEditSave = () => {
  //   setSelectedEquipment(undefined);
  //   setEditPopupVisible(false);
  //   // setSnackbar({
  //   //   message: responseMessage,
  //   //   severity: responseData ? SEVERITY.SUCCESS : SEVERITY.ERROR,
  //   // });
  // };

  // const handleEditCancel = () => {
  //   setSelectedEquipment(undefined);
  //   setEditPopupVisible(false);
  // };

  const columns = [
    {
      name: 'uniqueName',
      header: 'Equipment',
      defaultFlex: 1,
    },
    {
      name: 'manufacturer',
      header: 'Manufacturer',
      defaultFlex: 1,
      visible: !isMobile,
    },
    {
      name: 'modelNumber',
      header: 'Model Number',
      defaultFlex: 1,
      visible: !isMobile,
    },
    {
      id: 'actions',
      header: 'Actions',
      defaultFlex: 0.35,
      render: ({ data }: any) => (
        <div className = "flex justify-center">
          {/* <IconButton
            onClick={() => onEditClick(data)}
            size="small"
            aria-label="Edit item"
            disabled={disableEdit}
          >
            <EditIcon fontSize="inherit" />
          </IconButton> */}
          <IconButton
            onClick={() => onDeleteClick(data)}
            size="small"
            color="error"
            aria-label="Delete item"
            disabled={disableEdit}
          >
            <DeleteTwoToneIcon fontSize="inherit" />
          </IconButton>
        </div>
      ),
    },
  ];

  return (
    <>
        <div className="flex justify-end mb-4">
          <Button 
            variant="contained" 
            startIcon={<AddIcon />}
            onClick={handleAddClick} 
            disabled={disableEdit}
          >
            Add
          </Button>
        </div>

        <div data-testid="data-grid" className="flex flex-col flex-grow inv-spare">
          <LicensedReactDataGrid
            rowHeight={40}
            loading={equipmentForInventoryLoading}
            showColumnMenuTool={false}
            idProperty="id"
            columns={columns}
            dataSource={equipmentForInventory}
          />
        </div>

      <SpareForEquipmentAddDialog
        visible={addPopupVisible}
        excludedIds={excludedIds}
        onSave={handleAddSave}
        onCancel={handleAddCancel}
      />

      {/* <SpareForEquipmentEditDialog
        visible={editPopupVisible}
        initialValue={selectedEquipment}
        onSave={handleEditSave}
        onCancel={handleEditCancel}
      /> */}

      <WarningDialog
        visible={isDeleting}
        title="Delete Warning"
        content="Are you sure you wish to delete record?"
        okText='Yes'
        color='error'
        onOk={handleDeleteOk}
        onCancel={handleDeleteCancel}
      />

      <Snackbar
        open={!!snackbar}
        autoHideDuration={2000}
        onClose={() => setSnackbar(undefined)}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert severity={snackbar?.severity}>{snackbar?.message}</Alert>
      </Snackbar>
    </>
  );
};

export default SpareForEquipmentTab;
