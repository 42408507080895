import { FC } from 'react';
import AddIcon from '@mui/icons-material/Add';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import PhotoIcon from '@mui/icons-material/Photo';
import CommentIcon from '@mui/icons-material/Comment';
import SettingsSuggestIcon from '@mui/icons-material/SettingsSuggest';
import FactCheckIcon from '@mui/icons-material/FactCheck'; // based on verifiedReq
import DocumentScannerIcon from '@mui/icons-material/DocumentScanner'; //based on smsReport
import EventBusyIcon from '@mui/icons-material/EventBusy';
import ProductionQuantityLimitsIcon from '@mui/icons-material/ProductionQuantityLimits';

interface GridRowIconProps {
  hasAttachments?: boolean;
  hasPhotos?: boolean;
  hasComments?: boolean;
  hasSpares?: boolean;
  isCritical?: boolean;
  isReport?: boolean;
  requiresVerification?: boolean;
  expiredAtLocation?: boolean;
  lowStock?: boolean;
}

const GridRowIcon: FC<GridRowIconProps> = ({ 
  hasAttachments, 
  hasPhotos, 
  hasComments, 
  hasSpares, 
  isCritical, 
  isReport,
  requiresVerification,
  expiredAtLocation,
  lowStock,
}) => {
  return (
    <div className="flex">
      { hasAttachments && <AttachFileIcon fontSize='small' color='info'/> }
      { hasPhotos && <PhotoIcon fontSize='small' color='info'/>}
      { hasComments && <CommentIcon fontSize='small' color='success'/>}
      { hasSpares && <SettingsSuggestIcon fontSize='small' color='action'/>}
      { isCritical && <AddIcon fontSize='small' color='error'/> } 
      { isReport && <DocumentScannerIcon fontSize='small' color='info'/> } 
      { requiresVerification && <FactCheckIcon fontSize='small' color='warning'/> } 
      { expiredAtLocation && <EventBusyIcon fontSize='small' color='error'/> } 
      { lowStock && <ProductionQuantityLimitsIcon fontSize='small' color='error'/> } 
    </div>
  );
};

export default GridRowIcon;
