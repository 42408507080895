import { FC } from 'react';
import { isNil } from 'lodash';
import { Control, RegisterOptions, useController } from 'react-hook-form';
import { FormHelperText } from '@mui/material';
import { GridType } from 'src/consts';
import CardViewGenericTreeViewDropdown from 'src/components/GenericTreeViewDropdown/CardViewGenericTreeViewDropdown';
import GenericTreeViewDropdown from 'src/components/GenericTreeViewDropdown';

interface InjectedProps {
  control: Control;
  name: string;
  defaultValue?: any;
  rules?: RegisterOptions;
  label?: string;
  onChange: (field: string, value: any) => void;
  options: any[];
  valueExpr: string;
  displayExpr: string;
  parentIdExpr: string;
  keyExpr: string;
  disabled?: boolean;
  dropdownHeight?: number;
  gridType?: GridType;
}

const TreeSelect: FC<InjectedProps> = ({
  control,
  onChange,
  name,
  rules,
  defaultValue,
  label,
  valueExpr,
  displayExpr,
  options,
  children,
  keyExpr,
  parentIdExpr,
  disabled,
  dropdownHeight,
  gridType
}) => {
  const {
    field: { ref, ...inputProps },
    fieldState: { error },
  } = useController({
    name,
    control,
    defaultValue,
    rules,
  });

  const validation = isNil(error) ? 'valid' : 'invalid';

  return (
    <div className="flex flex-col">
      {gridType === GridType.CARDS ? 
        <CardViewGenericTreeViewDropdown
          {...inputProps}
          disabled={disabled}
          data={options}
          valueExpr={valueExpr}
          keyExpr={keyExpr}
          parentIdExpr={parentIdExpr}
          displayExpr={displayExpr}
          placeholder={label}
          onValueChanged={(e) => onChange(name, e.value)}
          children={children}
          validationStatus={validation}
          error={error}
          inputRef={ref}
          dropdownHeight={dropdownHeight}
        />
        :
        <GenericTreeViewDropdown
          {...inputProps}
          disabled={disabled}
          data={options}
          valueExpr={valueExpr}
          keyExpr={keyExpr}
          parentIdExpr={parentIdExpr}
          displayExpr={displayExpr}
          placeholder={label}
          onValueChanged={(e) => onChange(name, e.value)}
          children={children}
          validationStatus={validation}
          error={error}
          inputRef={ref}
          dropdownHeight={dropdownHeight}
        />
      }
      
      {/* {error && (
        <FormHelperText error={!!error}>{error?.message}</FormHelperText>
      )} */}
    </div>
  );
};

export default TreeSelect;
