import { gql, useQuery } from '@apollo/client';
import { DocumentRevision } from 'src/generated/dotnet.graphql';
import { logger } from 'src/helpers/logger';

export const GET_DOCUMENT_REVISIONS = gql`
  query GetDocumentRevisions($documentationId: String!) {
    documentRevisionsForDocumentation(documentationId: $documentationId) {
      altPath
      approvedByPe
      approvedByPm
      binderIndex
      binderLocation
      dateEntered
      documentationId
      fileName
      fileType
      id
      isRecoverable
      locationId
      notes
      page
      revision
      revisionDate
      status
    }
  }
`;

export interface DocumentRevisionsResult {
  revisions: DocumentRevision[];
  revisionsLoading: boolean;
  revisionsCount: number;
}

export const useGetDocumentRevisions = (documentationId: string): DocumentRevisionsResult => {
  const { data, loading, error } = useQuery(GET_DOCUMENT_REVISIONS, {
    variables: { documentationId },
    skip: !documentationId,
    notifyOnNetworkStatusChange: true,
  });

  if (error) {
    logger('GetDocumentRevisions').error(`Error fetching revisions for documentation: ${documentationId} -->`, error.message);
  }

  return {
    revisions: data?.documentRevisionsForDocumentation || [],
    revisionsLoading: loading,
    revisionsCount: data?.documentRevisionsForDocumentation.length, 
  };
};
