import { ApolloError, gql, useMutation } from '@apollo/client';
import { logger } from 'src/helpers/logger';
import { EquipmentForInventoryMutationResponse } from './useAddEquipmentForInventory';
import { GET__EQUIPMENT_FOR_INVENTORY } from './useGetEquipmentForInventory';

export const DELETE_EQUIPMENT_FOR_INVENTORY = gql`
  mutation DeleteEquipmentForInventory($input: EquipmentForInventoryInput!) {
    removeEquipmentFromInventory(input: $input) {
      equipment {
        id
        uniqueName
      }
    }
  }
`;

interface DeleteEquipmentFromInventoryResult {
  removeEquipmentForInventory: (equipmentIds: string[], inventoryId: string) => Promise<EquipmentForInventoryMutationResponse>;
  removeEquipmentForInventoryLoading: boolean;
}

export const useDeleteEquipmentFromInventory = (): DeleteEquipmentFromInventoryResult => {
  const [deleteEquipmentFromInventory, { loading }] = useMutation(DELETE_EQUIPMENT_FOR_INVENTORY, {
    onError: (error: ApolloError) => {
      logger('DeleteEquipmentForInventory').error('Error deleting equipment from inventory -->', error.message);
    }
  });

  const removeEquipmentForInventory = async (equipmentIds: string[], inventoryId: string): Promise<EquipmentForInventoryMutationResponse> => {
    const response = await deleteEquipmentFromInventory({ 
      variables: { 
        input: {
          equipmentIds,
          inventoryId
        } 
      },
      refetchQueries: [{
        query: GET__EQUIPMENT_FOR_INVENTORY,
        variables: { inventoryId }
      }],
    });
  
     const responseData = response.data?.removeEquipmentFromInventory?.equipment;
    if (responseData) {
      logger('DeleteEquipmentForInventory').info('Equipment deleted successfully from inventory', response.data);
      return {
        responseData,
        responseMessage: `Equipment from inventory removed successfully!`
      };
    } else {
      return {
        responseMessage: `Failed to remove equipment from inventory!`,
      };
    }
  };

  return { 
    removeEquipmentForInventory, 
    removeEquipmentForInventoryLoading: loading, 
  };
};
