import { FC, useState } from 'react';
import { Button, Snackbar, Alert, Tooltip, AlertColor } from '@mui/material';
import InventoryStorageDialog from './components/InventoryStorageDialog';
import { Inventory, InventoryLocation, RecordType } from 'src/generated/dotnet.graphql';
import StorageCardsGrid from './components/StorageCardsGrid';

interface Props {
  inventory: Inventory;
  recordType: RecordType;
  isCreate: boolean;
  refreshGridView: () => void;
  readOnly?: boolean;
}

const StorageLocation: FC<Props> = ({
  inventory,
  recordType,
  isCreate,
  refreshGridView,
  readOnly = false,
}) => {
  const [isStoragePopupVisible, setIsStoragePopupVisible] = useState<boolean>(false);
  const [selectedStorageLocation, setSelectedStorageLocation] = useState<InventoryLocation>();
  const [isCreateInventoryLocation, setIsCreateInventoryLocation] = useState(false);
  const [snackbar, setSnackbar] = useState<{ message: string; severity: AlertColor }>();

  const handleAddClick = async () => {
    setIsCreateInventoryLocation(true);
    setSelectedStorageLocation(undefined);
    setIsStoragePopupVisible(true);
  };

  const handleStorageDialogCancel = () => {
    setSelectedStorageLocation(undefined);
    setIsStoragePopupVisible(false);
  };

  const handleSelection = (item: InventoryLocation) => {
    setSelectedStorageLocation(item);
    setIsStoragePopupVisible(true);
  };

  const onSave = async () => {
    setSelectedStorageLocation(undefined);
    setIsStoragePopupVisible(false);
  };

  const onDelete = async () => {
    setSelectedStorageLocation(undefined);
    setIsStoragePopupVisible(false);
  };

  return (
    <>
      <div>
        <div className="flex justify-between mb-6">
          <div className="flex justify-start">
            {/* TODO - implement when refactoring Purchase module with the new dotnet api 
            {componentName === 'OrderItemAddDialog' &&
              <Box>
                <Typography fontSize={14} display="block" fontWeight="bold">
                  {`Amount Ordered: ${initialValue.Amount}`}
                </Typography>
                <Typography fontSize={14} display="block" fontWeight="bold">
                  {`Amount Received: ${initialValue.AmtReceived}`}
                </Typography>
              </Box>
            } */}
          </div>
          <div className="flex justify-end">
            {isCreate ? (
              <Tooltip
                title="Create Inventory to allocate Location"
                placement="left"
                arrow
              >
                <span>
                  <Button
                    variant="contained"
                    onClick={handleAddClick}
                    disabled={isCreate}
                  >
                    Add
                  </Button>
                </span>
              </Tooltip>
            ) : (
              <Button
                variant="contained"
                onClick={handleAddClick}
                disabled={readOnly}
              >
                Add
              </Button>
            )}
          </div>
        </div>
        <div>
          <StorageCardsGrid
            inventory={inventory}
            onDelete={onDelete}
            onSelect={handleSelection}
            refreshGridView={refreshGridView}
            readOnly={readOnly}
          />

          {/* // TODO - implement when refactoring Purchase module with the new dotnet api
          {componentName === 'OrderItemAddDialog' ? 
          (
            <InvStorageGrid
              initialValue={initialValue}
              onDelete={handleDelete}
              loadingGrid={loading}
              onSelect={hnadleReceiveItemSelection}
            />
          ) : (
            <StorageCardsGrid
              inventory={inventory}
              onDelete={onDelete}
              loadingGrid={loading}
              onSelect={handleSelection}
              readOnly={readOnly}
            />
          )} */}
        </div>

        <InventoryStorageDialog
          visible={isStoragePopupVisible}
          storageLocation={selectedStorageLocation}
          inventory={inventory}
          isCreate={isCreateInventoryLocation}
          onSave={onSave}
          onCancel={handleStorageDialogCancel}
          recordType={recordType}
        />
      </div>
      <Snackbar
        open={!!snackbar}
        autoHideDuration={2000}
        onClose={() => setSnackbar(undefined)}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert severity={snackbar?.severity}>{snackbar?.message}</Alert>
      </Snackbar>
    </>
  );
};

export default StorageLocation;
