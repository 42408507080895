import React, { FC, useEffect, useState } from 'react';
import { Control, UseFormWatch } from 'react-hook-form';
import { isNil } from 'lodash';

// MUI Components
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  FormGroup,
} from '@mui/material';
import { ExpandMore } from '@mui/icons-material';

// TDI Componenents
import Checkbox from 'src/components/UI/Forms/Checkbox';
import Input from 'src/components/UI/Forms/Input';
import { InventoryDocument } from 'src/pages/InventoryPage/rxdb';
import RichTextEditor from '../../../UI/Forms/RichTextEditor';
import Autocomplete from '../../../UI/Forms/Autocomplete';
import CategoryDropdownOld from '../../../Dropdowns/CategoryDropdown/indexOld';
import InvStorage from "src/components/dataentry/inventoryForm"

// Utilities
import { handleCharLimitWarning, toJSON } from '../../../../utils';

// Data
import { getDatabase } from '../../../../rxdb';
import { TblDdListDefaultsDocument } from '../../../../api/queries/tblDDListDefaults/rxdb';
import { Inventory } from '../../../../generated/graphql';
import { useAppState } from 'src/contexts/app-state';
import { CHAR_LIMIT } from 'src/consts';

interface InvGenSummaryFormProps {
  form: Inventory;
  initialValue: InventoryDocument;
  onChange: (field: string, value: any) => void;
  control: Control;
  watch: UseFormWatch<any>;
  getValues: any;
  onStorageUpdate: (isUpdate: boolean) => void;
  disableEdit? : boolean;
  isCreated? : boolean;
  selectedItemOrderID?: string;
  onSave: (invItem: Inventory, isCreated: boolean) => void;
}

const InvGenSummaryForm: FC<InvGenSummaryFormProps> = ({
  form,
  onChange,
  initialValue,
  control,
  watch,
  getValues,
  onStorageUpdate,
  disableEdit = false,
  isCreated= false,
  selectedItemOrderID = "",
  onSave,
}) => {
  const { settingsPersonal } = useAppState();
  const [departments, setDepartments] = useState<TblDdListDefaultsDocument[]>([]);
  const [usageMeasure, setUsageMeasure] = useState<TblDdListDefaultsDocument[]>([]);
  const [orderMeasure, setOrderMeasure] = useState<TblDdListDefaultsDocument[]>([]);
  const { INVENTORY } = CHAR_LIMIT;

  const init = async () => {
    const db = await getDatabase();

    db.tblddlistdefaults.find({ selector: { deletedBy: { $eq: null } }}).$.subscribe((defaults) => {
      const departments = defaults.filter((d) => d.fldListName === "Department" && d.fldMember !== "");
      const usageMeasure = defaults.filter((d) => d.fldListName === 'Qty' && d.fldMember !== "");
      const orderMeasure = defaults.filter((d) => d.fldListName === 'Qty' && d.fldMember !== "");
      setDepartments(toJSON(departments));
      setUsageMeasure(toJSON(usageMeasure));
      setOrderMeasure(toJSON(orderMeasure));
    });
  };

  useEffect(() => {
    if (isNil(initialValue)) return;

    init();
  }, [initialValue]);

  // ??Shouldn't this be it's own component?  It just gets used over and over
  const renderListDefaultBox = (
    items: TblDdListDefaultsDocument[],
    fieldName: string,
    fldListName: string,
    freeSolo = false,
    customListName: any = null,
    rules: Object | undefined = undefined,
  ) => (
    <Autocomplete
      rules={rules}
      defaultPayload={{
        fldListName,
        fldT4Reqd: true,
      }}
      displayExpr="fldMember"
      keyExpr="PKey"
      onChange={onChange}
      freeSolo={freeSolo}
      options={items}
      label={customListName || fldListName}
      control={control}
      name={fieldName}
      collection="tblddlistdefaults"
      disabled={fldListName === "Department" ? disableEdit: false}
    />
  );

  const onStorageUpdated = (isUpdated: boolean) => {
    if (isUpdated) {
      onStorageUpdate(isUpdated)
    }
  }

  return (
    <div className="mt-3">
      <Accordion defaultExpanded>
        <AccordionSummary
          expandIcon={<ExpandMore />}
          aria-controls="general-information"
          id="general-information"
        >
          General Information
        </AccordionSummary>
        <AccordionDetails>
          <div className="w-full flex flex-col">
            {/* Category Hierarchial, collapsable Tree */}
            <div className="mt-3">
              <CategoryDropdownOld
                name="fldSRHKey"
                label="Category"
                defaultValue={initialValue.fldSRHKey}
                control={control}
                onChange={onChange}
                rules={{
                  required: true,
                }}
                sourceForm="general-inventory"
                allDepts={settingsPersonal?.fldAllDepts > 0}
              />
            </div>

            <div className="pt-4">
              <Input
                name="ModelNumber"
                rules={{ maxLength: INVENTORY.modelNumber }}
                warning={(value) => handleCharLimitWarning(value, INVENTORY.modelNumber)}
                control={control}
                inputProps={{ label: 'Model Number' }}
              />
            </div>

            <div className="mt-3">
              {renderListDefaultBox(departments, 'Department', 'Department', true, null, { required: 'Department required' })}
            </div>

            <div className="mt-3">
              <FormGroup row>
                <Checkbox
                  control={control}
                  name="fldConsumable"
                  label="Consumable"
                />
                <Checkbox
                  control={control}
                  name="Bonded"
                  label="Bonded"
                />
                <Checkbox
                  control={control}
                  name="fldSMS"
                  label="Critical Inventory (SMS Required)"
                />
              </FormGroup>
            </div>
          </div>

          {/* TODO add fldHTML */}
          <div className="my-4">
            <RichTextEditor
              control={control}
              name="ProductDescription"
              onChange={onChange}
            />
          </div>
        </AccordionDetails>
      </Accordion>

      <Accordion defaultExpanded className="mt-3">
        <AccordionSummary
          expandIcon={<ExpandMore />}
          aria-controls="Storage Locations"
          id="storageLocations"
        >
          Storage Locations
        </AccordionSummary>
        <AccordionDetails>
          <div>
            <InvStorage
              form={form}
              onChange={onChange}
              initialValue={initialValue}
              control={control}
              watch={watch}
              getValues={getValues()}
              onSave={onStorageUpdated}
              isCreated = {isCreated}
              selectedItemOrderID={selectedItemOrderID}
              onSaveInv={onSave}
              disableEdit={disableEdit}
            />
          </div>
        </AccordionDetails>
      </Accordion>

      <Accordion className="mt-3">
        <AccordionSummary
          expandIcon={<ExpandMore />}
          aria-controls="Ordering Details"
          id="orderingDetails"
        >
          Ordering Details
        </AccordionSummary>
        <AccordionDetails>
          <div className="pt-4">
            <div className="flex flex-wrap">
              <div className="flex-grow p-2">
                <Input
                  name="ReOrderLevel"
                  inputProps={{
                    label: 'Min. Holding Level',
                    type: 'number',
                    inputProps: { min: 0, style: { textAlign: 'end' }, },
                  }}
                  control={control}
                  rules={{
                    min: 0,
                  }}
                />
              </div>
              <div className="flex-grow p-2">
                <Input
                  name="ReOrderAmt"
                  inputProps={{
                    label: 'Max. Holding Level',
                    type: 'number',
                    inputProps: { min: 0, style: { textAlign: 'end' }, },
                  }}
                  control={control}
                  rules={{
                    min: 0,
                  }}
                />
              </div>
            </div>

            <div className="flex flex-wrap">
              <div className="flex-grow p-2">
                {renderListDefaultBox(usageMeasure, 'Qty', 'Qty', true, 'Usage Measure')}
              </div>
              <div className="flex-grow p-2">
                {renderListDefaultBox(
                  orderMeasure,
                  'fldOrderQty',
                  'Qty',
                  true,
                  'Order Measure',
                )}
              </div>
            </div>
            <div className="p-2 w-1/2">
              <Input
                name="fldFactor"
                inputProps={{
                  label: 'Measure Factor',
                  type: 'number',
                  inputProps: {
                    inputMode: 'decimal',
                    pattern: '([0-9]+)?[,\\.]?[0-9]*',
                    step: 0.25,
                    style: { textAlign: 'end' },
                  },
                }}
                control={control}
                // TODO:revisit validation after edit works
                // defaultValue = {getValues().Qty === getValues().fldOrderQty ? 1.0 : initialValue.fldOrderQty}
                rules={{
                  min: 0,
                  // validate:()=>{
                  //   if(getValues().Qty === getValues().fldOrderQty && getValues().fldFactor != 1.0){
                  //     return "Usage Measure and Order Measure are same so default value will be 1.0"
                  //   }
                  // }
                }}
              />
            </div>
          </div>
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

export default InvGenSummaryForm;
